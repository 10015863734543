@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');

:root {
  --color-primary: #007CC2;
  --color-secondary: #000000;
  --bs-success-rgb: rgb(0, 124, 194) !important;
  --bs-success: #007CC2 !important;
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #007CC2 !important;
  --bs-btn-border-color: #007CC2 !important;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #007CC2 !important;
  --bs-btn-hover-border-color: #007CC2 !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #007CC2 !important;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #007CC2 !important;
  --bs-btn-disabled-border-color: #007CC2 !important;




  --neutral-100: #000000;
  --neutral-200: #FFFFFF;
  --neutral--600: #7f7f80;
  --neutral--800: #1b1311;
  --accent--primary-1: #151010;
  --neutral--100: white;
  --general--shadow-01: rgba(20, 20, 43, .06);
  --neutral--200: #f3f5fa;
  --neutral--700: #505051;
  --neutral--300: #ededed;
  --general--shadow-02: rgba(31, 25, 20, .08);
  --secondary--color-1: #1f1914;
  --neutral--500: #a6a7a8;
  --neutral--400: #cfcfd0;
  --shadow-input: rgba(19, 18, 66, .07);
  --system--green-400: #11845b;
  --system--blue-400: #086cd9;
  --system--blue-300: #1d88fe;
  --system--blue-200: #8fc3ff;
  --system--blue-100: #eaf4ff;
  --system--green-300: #05c168;
  --system--green-200: #7fdca4;
  --system--green-100: #def2e6;
  --system--red-400: #dc2b2b;
  --system--300: #ff5a65;
  --system--red-200: #ffbec2;
  --system--red-100: #ffeff0;
  --system--orange-400: #d5691b;
  --system--orange-300: #ff9e2c;
  --system--orange-200: #ffd19b;
  --system--orange-100: #fff3e4;
  --general--shadow-03: rgba(20, 20, 43, .1);
  --general--shadow-04: rgba(31, 25, 20, .14);
  --general--shadow-05: rgba(31, 25, 20, .16);
  --general--shadow-06: rgba(31, 25, 20, .24);
  --button-shadow--color-01: rgba(31, 25, 20, .06);
  --button-shadow--color-2: rgba(31, 25, 20, .08);
  --button-shadow--color-03: rgba(31, 25, 20, .12);
  --button-shadow--white-01: rgba(20, 20, 43, .04);
  --button-shadow--white-02: rgba(20, 20, 43, .06);
  --button-shadow--white-03: rgba(20, 20, 43, .1);
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Comfortaa";
  color: #444444;
  height: 100%;
}

a {
  color: #007CC2;
  text-decoration: none !important;
}

p {
  font-size: .85rem !important;
  font-family: "Comfortaa", "Open Sans";
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1) !important;
  text-align: justify !important;
}

a:hover {
  color: #EB1D2C;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Comfortaa", "Open Sans";
}

.mt-100 {
  margin-top: 100px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.ml-5 {
  margin-left: 50px !important;
}

.mr-5 {
  margin-right: 50px !important;
}

.ml-1 {
  margin-left: 10px !important;
}

.mr-1 {
  margin-right: 10px !important;
}


.blue-text {
  font-family: "Comfortaa", "Open Sans";
  color: #007CC2 !important;
}

.red-text {
  color: #EB1D2C !important;
}

.white-text {
  z-index: 1;
  color: #FFFFFF !important;
}

.awards-text {
  color: #FFFFFF !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.black-text {
  color: #000000 !important;
}

.italic {
  font-style: italic;
}

.text-justify {
  text-align: justify !important;
}

.text-success {
  color: #007CC2 !important;
}

.truncated-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* Prevents line breaks */
  max-width: 600px;
  /* Adjust the max width as needed */
}

.retail-image-fluid{
  width: 80%;
}

.retail-card {
  /* background-color: #eeeff1; */
  background-color: #FFFFFF;
  border-radius: 20px;
  box-shadow: 0 15px 32px rgba(23, 136, 228, 0.38);
}

/*--------------------------------------------------------------
# Button
--------------------------------------------------------------*/
.btn-primary {
  background: #007CC2 !important;
  border: 1px solid #007CC2;
  outline: #007CC2;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #007CC2;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #007CC2;
  border-top-color: #FFFFFF;
  border-bottom-color: #FFFFFF;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #007CC2;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #FFFFFF;
  line-height: 0;
}

.back-to-top:hover {
  background: #6bc1e9;
  color: #FFFFFF;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 10px 0px;
  background: rgba(0, 0, 0, 0);
  /* background: linear-gradient(90deg, rgba(0,123,194,1) 0%, rgba(235,29,44,1) 100%); */
}

#header.header-scrolled,
#header.header-inner-pages {
  background: linear-gradient(90deg, rgba(0, 123, 194, 1) 0%, rgba(235, 29, 44, 1) 100%);
  /* background: rgba(0, 124, 194, 0.9); */
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#header .logo a {
  color: #FFFFFF;
}

#header .logo img {
  max-height: 40px;
}


/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
  font-family: "Comfortaa", "Open Sans";
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
  font-family: "Comfortaa", "Open Sans";
}

.navbar li {
  position: relative;
  font-family: "Comfortaa", "Open Sans";
}

.navbar>ul>li {
  white-space: nowrap;
  padding: 8px 12px;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  padding: 0 3px;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 800;
  color: rgba(255, 255, 255, 0.9);
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar>ul>li>a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color: #EB1D2C;
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}

.navbar a:hover:before,
.navbar li:hover>a:before,
.navbar .active:before {
  visibility: visible;
  width: 100%;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #FFFFFF;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  padding: 8px 25px;
  margin-left: 30px;
  border-radius: 4px;
  color: #FFFFFF;
  border: 1.5px solid #FFFFFF;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #FFFFFF;
  background: #007CC2;
  border-color: #007CC2;
}

.navbar>ul>li>.getstarted:before {
  visibility: hidden;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #FFFFFF;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  color: #15222b;
  font-weight: 400;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #EB1D2C;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/*
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #FFFFFF !important;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .mt-100 {
    margin-top: 50px !important;
  }
  
  .pt-100 {
    padding-top: 50px !important;
  }
  

  .navbar ul {
    display: none;
  }

  .navbar-mobile ul {
    display: block;
    position: fixed !important;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #000000;
    overflow-y: auto;
    transition: 0.3s;
  }

  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

.navbar-mobile {
  min-width: 500px;
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  /* background: rgba(4, 7, 9, 0.9); */
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #FFFFFF;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #15222b;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #EB1D2C;
}

.navbar-mobile .getstarted {
  color: #EB1D2C;
  border: 1.5px solid #EB1D2C !important;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #000000;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #EB1D2C;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# IDaaS Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  position: relative;
  padding: 100px 0px 100px 0px;
}

.idaas-section {
  padding-top: 100px;
}

#idaas-hero {
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  position: relative;
  padding: 100px 0px 100px 0px;
}

#idaas-hero .service-item h6 {
  color: #FFFFFF !important;
}

#idaas-hero h1 {
  margin: 0 0 10px 0;
  font-size: 38px;
  font-weight: 700;
  line-height: 56px;
  text-transform: capitalize;
  color: #ffffff;
}

#idaas-hero h6 {
  color: #FFFFFF;
}

#idaas-hero a {
  color: #FFFFFF;
}

#idaas-hero .btn-secondary {
  border-radius: 1px solid #FFFFFF !important;
  color: #FFFFFF;
}

.idaas-btn-secondary {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  text-align: center;
  transform-style: preserve-3d;
  border-radius: 5px;
  padding: 15px 40px;
  line-height: 1.111em;
  transition: transform .3s, box-shadow .3s;
}

.idaas-btn-secondary:hover {
  border-color: #EB1D2C;
  background-color: rgb(235, 29, 44);
  border: 1px solid #EB1D2C;
  color: #FFFFFF;
  transform: translate3d(0, -6px, .01px);
  box-shadow: 0 15px 32px rgba(235, 29, 44, 0.38);
}

.idaas-btn-primary {
  background-color: rgba(235, 29, 44, 1);
  border: 1px solid #EB1D2C;
  color: #FFFFFF;
  text-align: center;
  transform-style: preserve-3d;
  border-radius: 5px;
  padding: 15px 40px;
  line-height: 1.111em;
  transition: transform .3s, box-shadow .3s;
}

.idaas-btn-primary:hover {
  border-color: #FFFFFF;
  background-color: rgb(0, 0, 0, 0);
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  transform: translate3d(0, -6px, .01px);
  box-shadow: 0 15px 32px rgba(235, 29, 44, 0.38);
}



/*--------------------------------------------------------------
# Fleet Hero Section
--------------------------------------------------------------*/
#fleet-hero {
  width: 100%;
  height: 100vh;
  background-size: cover;
  position: relative;
  padding: 0;
  z-index: 1;
}

.fleet-bg {
  background: url("../public/assets/img/fleet-bg-2.jpg") top center;
  background-size: cover;
  position: relative;
}

.fleet-hero-bg {
  background: url("../public/assets/img/mobifleet-1.jpg") top center;
  background-size: cover;
  
}

#fleet-hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

#fleet-hero .service-item h6 {
  color: #FFFFFF !important;
}

#fleet-hero h1 {
  margin: 0 0 10px 0;
  font-size: 38px;
  font-weight: 700;
  line-height: 56px;
  text-transform: capitalize;
  color: #ffffff;
}

#fleet-hero h6 {
  color: #FFFFFF;
}

#fleet-hero a {
  color: #FFFFFF;
}

#fleet-hero .btn-secondary {
  border-radius: 1px solid #FFFFFF !important;
  color: #FFFFFF;
}

.fleet-cta-1 {
  background: url("../public/assets/img/fleet-bg-1.jpg") center center;
  background-size: cover;
  position: relative;
}

.fleet-cta-1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgb(0, 0, 0) 40%, rgba(255, 255, 255, 0));
  pointer-events: none;
}

.card-title {
  text-align: center;
  margin-bottom: 20px !important;
}

.fleet-btn-secondary {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  text-align: center;
  transform-style: preserve-3d;
  border-radius: 5px;
  padding: 15px 40px;
  line-height: 1.111em;
  transition: transform .3s, box-shadow .3s;
}

.fleet-btn-secondary:hover {
  border-color: #EB1D2C;
  background-color: rgb(235, 29, 44);
  border: 1px solid #EB1D2C;
  color: #FFFFFF;
  transform: translate3d(0, -6px, .01px);
  box-shadow: 0 15px 32px rgba(235, 29, 44, 0.38);
}

.fleet-btn-primary {
  background-color: rgba(235, 29, 44, 1);
  border: 1px solid #EB1D2C;
  color: #FFFFFF;
  text-align: center;
  transform-style: preserve-3d;
  border-radius: 5px;
  padding: 15px 40px;
  line-height: 1.111em;
  transition: transform .3s, box-shadow .3s;
}

.fleet-btn-primary:hover {
  border-color: #FFFFFF;
  background-color: rgb(0, 0, 0, 0);
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  transform: translate3d(0, -6px, .01px);
  box-shadow: 0 15px 32px rgba(235, 29, 44, 0.38);
}

@media only screen and (max-width: 768px) {
  .about-hero-1, /* MobiPay-531.jpg */
  .about-us-hero-3 { /* MobiPay-328.jpg */
      display: none !important;
  }
}

/*--------------------------------------------------------------
# Retail Banking Hero Section
--------------------------------------------------------------*/
#retail-hero {
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100%;
  min-height: 100vh;
  background-size: cover;
}

#retail-hero .service-item h6 {
  color: #000000 !important;
}

.retail-hero-bg {
  /* background: url("../public/assets/img/hero-bg-11.png") top center; */
  background: url("../public/assets/img/hero-bg-4.png") top center;
  background-size: cover;
}

.mobi-banking-bg {
  /* background-image: url('../public/assets/img/retail-banking-image-1.png'); */
  background: #eeeff1;
  background-size: cover;
  background-position: center;
}

.bg-image {
  background-size: cover;
  background-position: center;
  height: 800px;
}

#retail-hero .retail-hero-container {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#about p {
  text-align: justify;
}

.stats-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.vas-border-left {
  /* border-right: 2px solid #FFFFFF; */
  justify-content: center;
  align-items: center;
  align-content: center;
}

.vas-stat-item {
  flex: 1;
  text-align: center;
  margin-bottom: 20px;
}

.vas-stat-item h3 {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  color: #FFFFFF !important;
}

.vas-stat-item h4 {
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  color: #FFFFFF !important;
}

.vas-stat-item h6 {
  font-size: 14px;
}





.stat-item {
  flex: 1;
  text-align: center;
  margin-bottom: 20px;
}

.stat-title{
  color: #000000 !important;
  /* text-align: start; */
}

.stat-item h3 {
  font-size: 28px;
  font-weight: bold;
  /* text-align: center; */
}

.stat-item h6 {
  font-size: 14px;
}

/* Make statistics responsive */
@media (max-width: 576px) {
  .stat-item {
    flex: 0 0 50%; /* Each statistic takes 50% width on small screens */
  }
}
St

#retail-hero p {
  text-align: start;
}

#retail-hero h1 {
  margin: 0 0 10px 0;
  font-size: 38px;
  font-weight: 700;
  line-height: 56px;
  /* text-align: start; */
  /* margin-right: 30px; */
  /* margin-left: 30px; */
  text-transform: capitalize;
  color: #000000;
}

#retail-hero h2 {
  color: #eee;
  margin-bottom: 50px;
  font-size: 24px;
}

#retail-hero h6, h3 {
  color: #000000 !important;
  text-align: start;
  /* margin: 20px; */
}

#retail-hero .btn-get-started {
  font-family: "Comfortaa", "Open Sans";
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  padding: 10px 35px 10px 35px;
  border-radius: 4px;
  transition: 0.5s;
  color: #FFFFFF;
  background: #EB1D2C;
  border: 2px solid #EB1D2C;
}

#retail-hero .btn-get-started:hover {
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

#retail-hero .btn-watch-video {
  font-size: 16px;
  display: inline-block;
  transition: 0.5s;
  margin-left: 25px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#retail-hero .btn-watch-video i {
  line-height: 0;
  color: #007CC2 !important;
  font-size: 32px;
  transition: 0.3s;
  margin-right: 8px;
}

#landing-hero .btn-watch-video {
  font-size: 16px;
  display: inline-block;
  transition: 0.5s;
  margin-left: 25px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#landing-hero .btn-watch-video i {
  line-height: 0;
  color: #007CC2 !important;
  font-size: 32px;
  transition: 0.3s;
  margin-right: 8px;
}

.vision {
  font-size: 48px;
}

#retail-hero .btn-watch-video:hover,
i:hover {
  color: #EB1D2C !important;
}

@media (min-width: 1024px) {
  #retail-hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #retail-hero {
    width: auto;
  }

  #retail-hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #retail-hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

/*--------------------------------------------------------------
# MobiVend Hero Section
--------------------------------------------------------------*/
#vas-hero {
  width: 100%;
  min-height: 100vh;
  padding: 120px 0px 100px 0px !important;
  background-size: cover;
  position: relative;
  z-index: 1;
}

#vas-hero .service-item h6 {
  color: #FFFFFF !important;
}

#vas-hero .stretched-link {
  color: #FFFFFF !important;
}

.vas-hero-bg {
  background: url("../public/assets/img/vas-image-2.png") top center;
  background-size: cover;
  
}

#vas-hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

#vas-hero .hero-container {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#vas-hero h1 {
  margin: 0 0 10px 0;
  font-size: 38px;
  font-weight: 700;
  line-height: 56px;
  text-transform: capitalize;
  color: #FFFFFF;
}

#vas-hero h2 {
  color: #EEEEEE;
  margin-bottom: 50px;
  font-size: 24px;
}

#vas-hero h6{
  color: #ffffff !important;
  text-align: center;
}

#vas-hero .btn-get-started {
  font-family: "Comfortaa", "Open Sans";
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  padding: 10px 35px 10px 35px;
  border-radius: 4px;
  transition: 0.5s;
  color: #FFFFFF;
  background: #EB1D2C;
  border: 2px solid #EB1D2C;
}

#vas-hero .btn-get-started:hover {
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

#vas-hero .btn-watch-video {
  font-size: 16px;
  display: inline-block;
  transition: 0.5s;
  margin-left: 25px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#vas-hero .btn-watch-video i {
  line-height: 0;
  color: #FFFFFF !important;
  font-size: 32px;
  transition: 0.3s;
  margin-right: 8px;
}

#vas-hero .btn-watch-video:hover,
i:hover {
  color: #EB1D2C !important;
}

.vas-cta-1 {
  background: url("../public/assets/img/vas-image-2.png") center center;
  background-size: cover;
  position: relative;
}

.vas-cta-1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgb(0, 0, 0, 0.95) 30%, rgba(255, 255, 255, 0) 100%);
  pointer-events: none;
}

.vas-video-section {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.hero-section {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.video-section {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-fullwidth {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.btn-play {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 20px;
  font-size: 24px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.btn-play:hover {
  background: rgba(0, 0, 0, 0.9);
}

.video-background {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: auto;
  height: 100%;
  z-index: -1;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  z-index: -1; 
}

@media (min-width: 1024px) {
  #vas-hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #vas-hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #vas-hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}


/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.bg-grad-1 {
  background: url("../public/assets/img/background-bg-4.svg") top center;
}

.hero-bg {
  background: url("../public/assets/img/gradient-bg-1.svg") top center;
}

.idaas-bg {
  background: url("../public/assets/img/idaas-bg-9.png") top center;
  background-size: cover;
  position: relative;
}

.idaas-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)); */
  background: linear-gradient(to right, rgb(0, 0, 0), rgba(255, 255, 255, 0));
  /* background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)); */
  /* background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)); */
  pointer-events: none;
}



.aws-bg {
  background: url("../public/assets/img/aws-image-2.png") center left;
  background-size: cover;
  position: relative;
}

.aws-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgb(0, 0, 0) 40%, rgba(255, 255, 255, 0) 70%);
  pointer-events: none;
}


.contact-title {
  font-weight: 800;
  font-size: 62px;
  color: #000000;
}

#contact {
  padding-top: 200px;
  width: 100%;
  min-height: 100vh;
  background-size: cover;
}

.get-in-touch {
  background: url("../public/assets/img/bg-23.png") top center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.contact {
  /* background: url("../public/assets/img/bg-23.png") top center; */
  /* background: linear-gradient(to bottom, #007CC2 10%, #FFFFFF 50%); */
  background: url("../public/assets/img/bg-gradient-1.png") top center;
  background: url("../public/assets/img/hero-bg-4.png") top center;
  /* background: linear-gradient(to bottom, url('../public/assets/img/hero-1.jpg') 50%, #FFFFFF 50%); */
  /* background: linear-gradient(to bottom, #007CC2 10%, #FFFFFF 50%); */
    /* background: (90deg, rgba(0, 123, 194, 1) 0%, rgba(235, 29, 44, 1) 100%) 50% 0% / 50% 100%, #FFFFFF 50% 0% / 50% 100%; */
    /* background: url('../public/assets/img/contact-us-3.jpg') top / 100% 50% no-repeat, #FFFFFF bottom / 100% 50%; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.contact::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(to bottom, rgb(0, 0, 0), rgba(255, 255, 255, 0)); */
  pointer-events: none;
}

#loyalty-cta-1 {
  width: 100%;
  height: 80vh;
  background-size: cover;
  position: relative;
  padding: 0;
}

.loyalty-cta-1 {
  background: url("../public/assets/img/retail-bg-1.jpg") center center;
  background-size: cover;
  position: relative;
}

.loyalty-cta-1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgb(0, 0, 0) 30%, rgba(255, 255, 255, 0));
  pointer-events: none;
}

#aws-cta-1 {
  width: 100%;
  height: 80vh;
  background-size: cover;
  position: relative;
  padding: 0;
}

.aws-cta-1 {
  background: url("../public/assets/img/aws-image-2.png") center center;
  background-size: cover;
  position: relative;
}

.aws-cta-1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgb(0, 0, 0) 40%, rgba(255, 255, 255, 0));
  pointer-events: none;
}

#idaas-cta-1 {
  width: 100%;
  height: 80vh;
  background-size: cover;
  position: relative;
  padding: 0;
}

.idaas-cta-1 {
  background: url("../public/assets/img/idaas-bg-6.png") center center;
  background-size: cover;
  position: relative;
}

.idaas-cta-1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgb(0, 0, 0), rgba(255, 255, 255, 0));
  pointer-events: none;
}



.retail-bg {
  background: url("../public/assets/img/retail-bg-1.jpg") top center;
}

.aws-bg {
  background: url("../public/assets/img/aws-image-2.png") center center;
}

.vas-bg {
  background: url("../public/assets/img/idaas-bg-1.jpg") top center;
}

.news-bg {
  background: url("../public/assets/img/blog/news-bg-2.jpg") top center
}

.loyalty-bg {
  background: url("../public/assets/img/mobiloyalty-1.jpg") top center
}

.vacancies-bg {
  background: url("../public/assets/img/vacancies-bg-1.jpg") top center;
}

#hero:before {
  content: "";
  background: rgba(13, 20, 26, 0.8);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.news-title {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
  color: #000000;
}

.hero-with-overlay {
  position: relative;
}

.hero-with-overlay::before {
  content: "";
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0; 
}

.hero-with-overlay .container {
  position: relative;
  z-index: 1; /* Make sure the content is above the overlay */
}

.gradient-text {
  background-image: linear-gradient(90deg, rgba(0, 123, 194, 1) 0%, rgba(235, 29, 44, 1) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  /* text-shadow: 3px 3px 6px rgba(23, 136, 228, 0.38); */
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
}

#hero .hero-container {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#about p {
  text-align: justify;
}

#hero h1 {
  margin: 0 0 10px 0;
  font-size: 38px;
  font-weight: 700;
  line-height: 56px;
  /* margin-right: 30px; */
  /* margin-left: 30px; */
  text-transform: capitalize;
  color: #FFFFFF;
}

#hero h2 {
  color: #eee;
  margin-bottom: 50px;
  font-size: 24px;
}

#hero h6 {
  color: #FFFFFF;
  /* margin: 20px; */
}

#hero .btn-get-started {
  font-family: "Comfortaa", "Open Sans";
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  padding: 15px 40px;
  border-radius: 5px;
  transition: 0.5s;
  color: #FFFFFF;
  background: #EB1D2C;
  border: 2px solid rgb(235, 29, 44);
  line-height: 1.111em;
  transition: transform .3s, box-shadow .3s;
  box-shadow: 0 8px 22px 0 var(--shadow-button-primary);
  text-align: center;
  transform-style: preserve-3d;
}

#hero .btn-get-started:hover {
  border-color: #FFFFFF;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgb(255, 255, 255);
  transform: translate3d(0, -6px, .01px);
  box-shadow: 0 15px 32px rgba(235, 29, 44, 0.38);
}

#hero .btn-watch-video {
  font-size: 16px;
  display: inline-block;
  transition: 0.5s;
  margin-left: 25px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#hero .btn-watch-video i {
  line-height: 0;
  color: #FFFFFF !important;
  font-size: 32px;
  transition: 0.3s;
  margin-right: 8px;
}

.blog-details p, li {
  font-size: 16px !important;
  font-family: 'Comfortaa'
}

.blog-details img {
  border-radius: 30px !important;
}

.vision {
  font-size: 48px;
}

#hero .btn-watch-video:hover,
i:hover {
  color: #EB1D2C !important;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 100px 0;
  overflow: hidden;
}

.section-bg {
  /* background: url("../public/assets/img/bg-23.png") top center; */
  background-color: #f3f5fa;

}

.section-title {
  text-align: center;
  margin-top: 30px;
  padding-top: 10px;
  padding-bottom: 30px;
}


.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  /* text-transform: uppercase; */
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #007CC2;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #007CC2;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}

.service-img {
  max-width: 60%;
  height: auto;
}

.vas-img {
  max-width: 150px !important;
  height: auto;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  padding: 5px 0;
  text-align: center;
}

.clients img {
  max-width: 45%;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 5px 0;
  filter: grayscale(100);
}

.clients img:hover {
  filter: none;
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .clients img {
    max-width: 40%;
  }
}

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-left: 28px;
  position: relative;
}

.about .content ul li+li {
  margin-top: 10px;
}

.about .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #007CC2;
  line-height: 1;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.btn-learn-more {
  font-family: "Comfortaa", "Open Sans";
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 4px;
  transition: 0.3s;
  line-height: 1;
  color: #007CC2;
  animation-delay: 0.8s;
  margin-top: 6px;
  border: 2px solid #007CC2;
}

.btn-learn-more i {
  color: #007CC2 !important;
}

.btn-learn-more:hover {
  border: 2px solid #EB1D2C;
  color: #EB1D2C;
}

.about .content .btn-learn-more:hover {
  background: #FFFFFF !important;
  color: #EB1D2C !important;
  text-decoration: none;
}

.about .count-box {
  /* padding: 60px 0; */
  width: 100%;
  font-family: "Comfortaa", "Open Sans";
}

.about .count-box i {
  display: block;
  font-size: 48px;
  color: #FFFFFF;
  float: left;
  line-height: 0;
}

.about .count-box span {
  font-size: 28px;
  line-height: 25px;
  display: block;
  font-weight: 700;
  color: #FFFFFF;
  margin-left: 60px;
}

.about .count-box p {
  padding: 5px 0 0 0;
  margin: 0 0 0 60px;
  font-family: "Comfortaa", "Open Sans";
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
}

.about .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #FFFFFF;
  font-size: 15px;
  font-family: "Comfortaa", "Open Sans";
  transition: ease-in-out 0.3s;
}

.about .count-box a:hover {
  color: #477392;
}

#about .bx {
  color: #000000 !important;
  background-color: #000000;
}

.about .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(#EB1D2C 50%, rgba(255, 74, 23, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.about .play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.about .play-btn::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-btn 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(255, 74, 23, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.about .play-btn:hover::after {
  border-left: 15px solid #EB1D2C;
  transform: scale(20);
}

.about .play-btn:hover::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  animation: none;
  border-radius: 0;
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Statistics Section
--------------------------------------------------------------*/
.statistics {
  background-color: #007CC2;
}

#statistics p,
i {
  color: #FFFFFF !important;
}

/*--------------------------------------------------------------
# Idaas FAQs
--------------------------------------------------------------*/
.idaas-faqs {
  background: #FFFFFF;
}

.idaas-faqs p {
  color: #000000;
}

.idaas-faqs .content h3 {
  font-weight: 400;
  font-size: 34px;
  color: #000000;
}

.idaas-faqs .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}

.idaas-faqs .content p {
  font-size: 15px;
  color: #000000 !important;
}

.idaas-faqs .img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.idaas-faqs .accordion-list p {
  color: #000000;
}

.idaas-faqs .accordion-list ul {
  padding: 0;
  list-style: none;
}

.idaas-faqs .accordion-list li+li {
  margin-top: 15px;
}

.idaas-faqs .accordion-list li {
  padding: 20px;
  background: transparent;
  border-radius: 10px;
  border: 1.5px solid #000000;
}

.idaas-faqs .accordion-list a {
  display: block;
  position: relative;
  font-family: "Comfortaa", "Open Sans";
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 30px;
  outline: none;
  cursor: pointer;
  color: #000000;
}

.idaas-faqs .accordion-list span {
  color: #000000;
  font-weight: 600;
  font-size: 18px;
  padding-right: 10px;
}

.idaas-faqs .accordion-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.idaas-faqs .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.idaas-faqs .accordion-list .icon-show {
  display: none;
}

.idaas-faqs .accordion-list a.collapsed {
  color: #000000;
}

.idaas-faqs .accordion-list a.collapsed:hover {
  color: #000000;
}

.idaas-faqs .accordion-list a.collapsed .icon-show {
  display: inline-block;
}

.idaas-faqs .accordion-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1024px) {

  .idaas-faqs .content,
  .idaas-faqs .accordion-list {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .idaas-faqs .img {
    min-height: 400px;
  }

  .idaas-faqs .content {
    padding-top: 30px;
  }

  .idaas-faqs .accordion-list {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .idaas-bg .img {
    min-height: 200px;
  }
}












/*
Why US
*/

.why-us {
  background: linear-gradient(90deg, rgba(0, 123, 194, 1) 0%, rgba(235, 29, 44, 1) 100%);
}

.why-us p {
  color: #FFFFFF;
}

.why-us .content h3 {
  font-weight: 400;
  font-size: 34px;
  color: #FFFFFF;
}

.why-us .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}

.why-us .content p {
  font-size: 15px;
  color: #FFFFFF !important;
}

.why-us .img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.why-us .accordion-list p {
  color: #FFFFFF;
}

.why-us .accordion-list ul {
  padding: 0;
  list-style: none;
}

.why-us .accordion-list li+li {
  margin-top: 15px;
}

.why-us .accordion-list li {
  padding: 20px;
  background: transparent;
  border-radius: 10px;
  border: 1.5px solid #FFFFFF;
}

.why-us .accordion-list a {
  display: block;
  position: relative;
  font-family: "Comfortaa", "Open Sans";
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 30px;
  outline: none;
  cursor: pointer;
  color: #FFFFFF;
}

.why-us .accordion-list span {
  color: #FFFFFF;
  font-weight: 600;
  font-size: 18px;
  padding-right: 10px;
}

.why-us .accordion-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.why-us .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.why-us .accordion-list .icon-show {
  display: none;
}

.why-us .accordion-list a.collapsed {
  color: #FFFFFF;
}

.why-us .accordion-list a.collapsed:hover {
  color: #FFFFFF;
}

.why-us .accordion-list a.collapsed .icon-show {
  display: inline-block;
}

.why-us .accordion-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1024px) {

  .why-us .content,
  .why-us .accordion-list {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .why-us .img {
    min-height: 400px;
  }

  .why-us .content {
    padding-top: 30px;
  }

  .why-us .accordion-list {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .why-us .img {
    min-height: 200px;
  }
}

/*--------------------------------------------------------------
# Skills
--------------------------------------------------------------*/
.skills .content h3 {
  font-weight: 700;
  font-size: 32px;
  color: #007CC2;
  font-family: "Comfortaa", "Open Sans";
}

.skills .content ul {
  list-style: none;
  padding: 0;
}

.skills .content ul li {
  padding-bottom: 10px;
}

.skills .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #007CC2;
}

.skills .content p:last-child {
  margin-bottom: 0;
}

.skills .progress {
  height: 60px;
  display: block;
  background: none;
  border-radius: 0;
}

.skills .progress .skill {
  padding: 0;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Comfortaa", "Open Sans";
  color: #007CC2;
}

.skills .progress .skill .val {
  float: right;
  font-style: normal;
}

.skills .progress-bar-wrap {
  background: #e8edf5;
  height: 10px;
}

.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: 0.9s;
  background-color: #4668a2;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 0 15px 32px rgba(23, 136, 228, 0.38);
  /* background: #f3f5fa; */
}

.vas-services {
  background: #f3f7f7;
} 

/* #business-case .icon-box {
  margin-bottom: 0px;
  padding: 0px;
  border-radius: 0px;
  background: #f3f7f7;
} */

.services .icon-box i {
  float: left;
  color: #007CC2;
  font-size: 28px;
  line-height: 1;
}

.services .icon-box h4 {
  margin-left: 50px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .icon-box h4 a {
  color: #007CC2;
  transition: 0.3s;
}

.services .icon-box .icon-box:hover h4 a {
  color: #EB1D2C;
}

.services .icon-box p {
  margin-left: 50px;
  line-height: 24px;
  font-size: 14px;
}

.services .icon-box:hover h4 a {
  color: #EB1D2C;
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
  /* background: linear-gradient(rgba(40, 58, 90, 0.9), rgba(40, 58, 90, 0.9)), url("../public/assets/img/cta-bg.jpg") fixed center center; */
  background: linear-gradient(to bottom, #f3f5fa 50%, #FFFFFF 50%);
  background-size: cover;
  padding: 0px !important;
}

.cta h3 {
  color: #FFFFFF;
  font-size: 28px;
  font-weight: 700;
}

.cta p {
  color: #FFFFFF;
}

.cta .cta-btn {
  font-family: "Comfortaa", "Open Sans";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #FFFFFF;
  color: #FFFFFF;
}

.cta .cta-btn:hover {
  background: #007CC2;
  border: 2px solid #007CC2;
}

@media (max-width: 1024px) {
  .cta {
    background-attachment: scroll;
  }
}

@media (min-width: 769px) {
  .cta .cta-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio #portfolio-flters {
  list-style: none;
  margin-bottom: 20px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  margin: 10px 5px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  color: #444444;
  transition: all 0.3s;
  padding: 8px 20px;
  border-radius: 50px;
  font-family: "Comfortaa", "Open Sans";
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  background: #007CC2;
  color: #FFFFFF;
}

.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio .portfolio-item .portfolio-img {
  overflow: hidden;
}

.portfolio .portfolio-item .portfolio-img img {
  transition: all 0.6s;
}

.portfolio .portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  left: 15px;
  bottom: 0;
  z-index: 3;
  right: 15px;
  transition: all 0.3s;
  background: rgba(55, 81, 126, 0.8);
  padding: 10px 15px;
}

.portfolio .portfolio-item .portfolio-info h4 {
  font-size: 18px;
  color: #FFFFFF;
  font-weight: 600;
  color: #FFFFFF;
  margin-bottom: 0px;
}

.portfolio .portfolio-item .portfolio-info p {
  color: #f9fcfe;
  font-size: 14px;
  margin-bottom: 0;
}

.portfolio .portfolio-item .portfolio-info .preview-link,
.portfolio .portfolio-item .portfolio-info .details-link {
  position: absolute;
  right: 40px;
  font-size: 24px;
  top: calc(50% - 18px);
  color: #FFFFFF;
  transition: 0.3s;
}

.portfolio .portfolio-item .portfolio-info .preview-link:hover,
.portfolio .portfolio-item .portfolio-info .details-link:hover {
  color: #007CC2;
}

.portfolio .portfolio-item .portfolio-info .details-link {
  right: 10px;
}

.portfolio .portfolio-item:hover .portfolio-img img {
  transform: scale(1.15);
}

.portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #FFFFFF;
  opacity: 1;
  border: 1px solid #007CC2;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #007CC2;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(55, 81, 126, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}


/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {
  position: relative;
}

.team .container {
  position: relative;
  z-index: 10;
}

.team .member {
  margin-bottom: 80px;
  position: relative;
}

.team .member .pic {
  overflow: hidden;
  max-height: 450px !important;
  border-radius: 20px;
}

.team .member .member-info {
  position: absolute;
  bottom: -50px;
  left: 20px;
  right: 20px;
  background: #fff;
  padding: 20px 15px;
  color: #15222b;
  /* box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1); */
  box-shadow: 0 15px 32px rgba(23, 136, 228, 0.38);
  overflow: hidden;
  transition: 0.5s;
  border-radius: 10px;
}

.team .member h4 {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 16px;
  color: #15222b;
  position: relative;
  padding-bottom: 10px;
}

.team .member h4::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #7fa5c0;
  bottom: 0;
  left: 0;
}

.team .member span {
  /* font-style: italic; */
  display: block;
  font-size: 13px;
}

.team .member .social {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.team .member .social a {
  transition: color 0.3s;
  color: #477392;
}

.team .member .social a:hover {
  color: #EB1D2C;
}

.team .member .social i {
  font-size: 16px;
  margin: 0 2px;
  color: #444444 !important;
  /* background-color: #FFFFFF; */
}

@media (max-width: 992px) {
  .team .member {
    margin-bottom: 110px;
  }
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .row {
  padding-top: 40px;
}

.pricing .box {
  padding: 60px 40px;
  box-shadow: 0 3px 20px -2px rgba(20, 45, 100, 0.1);
  background: #FFFFFF;
  height: 100%;
  border-top: 4px solid #FFFFFF;
  border-radius: 5px;
}

.pricing h3 {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 20px;
  color: #007CC2;
}

.pricing h4 {
  font-size: 48px;
  color: #007CC2;
  font-weight: 400;
  font-family: "Comfortaa", "Open Sans";
  margin-bottom: 25px;
}

.pricing h4 sup {
  font-size: 28px;
}

.pricing h4 span {
  color: #007CC2;
  font-size: 18px;
  display: block;
}

.pricing ul {
  padding: 20px 0;
  list-style: none;
  color: #999;
  text-align: left;
  line-height: 20px;
}

.pricing ul li {
  padding: 10px 0 10px 30px;
  position: relative;
}

.pricing ul i {
  color: #28a745;
  font-size: 24px;
  position: absolute;
  left: 0;
  top: 6px;
}

.pricing ul .na {
  color: #ccc;
}

.pricing ul .na i {
  color: #ccc;
}

.pricing ul .na span {
  text-decoration: line-through;
}

.pricing .buy-btn {
  display: inline-block;
  padding: 12px 35px;
  border-radius: 50px;
  color: #007CC2;
  transition: none;
  font-size: 16px;
  font-weight: 500;
  font-family: "Comfortaa", "Open Sans";
  transition: 0.3s;
  border: 1px solid #007CC2;
}

.pricing .buy-btn:hover {
  background: #007CC2;
  color: #FFFFFF;
}

.pricing .featured {
  border-top-color: #007CC2;
}

.pricing .featured .buy-btn {
  background: #007CC2;
  color: #FFFFFF;
}

.pricing .featured .buy-btn:hover {
  background: #23a3df;
}

@media (max-width: 992px) {
  .pricing .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 767px) {
  .pricing .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 420px) {
  .pricing .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .faq-list {
  padding: 0 100px;
}

.faq .faq-list ul {
  padding: 0;
  list-style: none;
}

.faq .faq-list li+li {
  margin-top: 15px;
}

.faq .faq-list li {
  padding: 20px;
  background: #FFFFFF;
  border-radius: 4px;
  position: relative;
}

.faq .faq-list a {
  display: block;
  position: relative;
  font-family: "Comfortaa", "Open Sans";
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
}

.faq .faq-list .icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 20px;
  color: #007CC2;
}

.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list a.collapsed {
  color: #007CC2;
  transition: 0.3s;
}

.faq .faq-list a.collapsed:hover {
  color: #007CC2;
}

.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1200px) {
  .faq .faq-list {
    padding: 0;
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
#why-us section {
  padding-bottom: 0px !important;
}

.contact .info {
  /* border-top: 3px solid #007CC2; */
  /* border-bottom: 3px solid #007CC2; */
  padding-top: 50px;
  /* background: #FFFFFF; */
  width: 100%;
  /* box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1); */
  /* border-radius: 10px; */
}

.contact .info i {
  font-size: 20px;
  color: #007CC2;
  /* color: #000000; */
  float: left;
  width: 44px;
  height: 44px;
  background: #007CC2;
  /* background: #000000; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #000000;
}

.contact .info p {
  padding: 0 0 10px 60px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #000000;
}

.contact .info .email p {
  padding-top: 5px;
}

.contact .info .social-links {
  padding-left: 60px;
}

.contact .info .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #333;
  color: #FFFFFF;
  line-height: 1;
  padding: 8px 0;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  margin-right: 10px;
}

.contact .info .social-links a:hover {
  background: #EB1D2C;
  color: #FFFFFF;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #EB1D2C;
  color: #FFFFFF;
}

.contact .email-form {
  width: 100%;
  border-top: 3px solid #007CC2;
  border-bottom: 3px solid #007CC2;
  padding: 40px;
  background: #FFFFFF;
  /* box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12); */
  box-shadow: 0 15px 32px rgba(23, 136, 228, 0.38);
  border-radius: 10px;
}

.contact .email-form .form-group {
  padding-bottom: 8px;
}

.contact .email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .email-form .error-message {
  display: none;
  color: #FFFFFF;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .email-form .error-message br+br {
  margin-top: 25px;
}

.contact .email-form .sent-message {
  display: none;
  color: #FFFFFF;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .email-form .loading {
  display: none;
  background: #FFFFFF;
  text-align: center;
  padding: 15px;
}

.contact .email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .email-form .form-group {
  margin-bottom: 20px;
}

.contact .email-form label {
  padding-bottom: 8px;
}

.contact .email-form input,
.contact .email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .email-form input:focus,
.contact .email-form textarea:focus {
  border-color: #007CC2;
}

.contact .email-form input {
  height: 44px;
}

.contact .email-form textarea {
  padding: 10px 12px;
}

.contact-card {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 20px 30px 20px;
  border-radius: 15px;
  box-shadow: 0 15px 32px rgba(23, 136, 228, 0.38);
}

/* .contact .email-form button[type=submit] {
  background: #007CC2;
  border: 0;
  padding: 12px 34px;
  color: #FFFFFF;
  transition: 0.4s;
  border-radius: 10px;
}

.contact .email-form button[type=submit]:hover {
  background: #209dd8;
} */

.contact .email-form button[type=submit] {
  background-color: #EB1D2C;
  border: 1px solid #EB1D2C;
  /* box-shadow: 0 8px 22px 0 var(--shadow-button-primary); */
  color: #FFFFFF;
  text-align: center;
  transform-style: preserve-3d;
  border-radius: 5px;
  padding: 15px 40px;
  line-height: 1.111em;
  transition: transform .3s, box-shadow .3s;
}

.contact .email-form button[type=submit]:hover {
  color: #007CC2;
  background-color: transparent;
  border: 1px solid rgb(0, 124, 194);
  transform: translate3d(0, -6px, .01px);
  box-shadow: 0 15px 32px rgba(23, 136, 228, 0.38);
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #f3f5fa;
  min-height: 40px;
  margin-top: 72px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 68px;
  }
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 600;
  color: #007CC2;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #4668a2;
  content: "/";
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  font-size: 14px;
  background: #007CC2;
}

#footer .footer-newsletter {
  padding: 50px 0;
  background: #f3f5fa;
  text-align: center;
  font-size: 15px;
  color: #FFFFFF;
}

#footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  color: #007CC2;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #FFFFFF;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
  text-align: left;
}

#footer .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #007CC2;
  color: #FFFFFF;
  transition: 0.3s;
  border-radius: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type=submit]:hover {
  background: #209dd8;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  background: linear-gradient(90deg, rgba(0, 123, 194, 1) 0%, rgba(235, 29, 44, 1) 100%);
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 28px;
  margin: 0 0 10px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 600;
  color: #FFFFFF;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Comfortaa", "Open Sans";
  color: #FFFFFF;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #007CC2;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #FFFFFF;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #007CC2;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #007CC2;
  color: #FFFFFF;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 32px;
  height: 32px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #FFFFFF;
  color: #FFFFFF;
  text-decoration: none;
}

#footer .footer-bottom {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #FFFFFF;
}

#footer .copyright {
  float: left;
}

#footer .credits {
  float: right;
  font-size: 13px;
}

#footer .credits a {
  transition: 0.3s;
}

@media (max-width: 768px) {
  #footer .footer-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  #footer .copyright,
  #footer .credits {
    text-align: center;
    float: none;
  }

  #footer .credits {
    padding-top: 4px;
  }
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .feature-box {
  padding: 24px 20px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  transition: 0.3s;
  height: 100%;
}

.features .feature-box h3 {
  font-size: 18px;
  color: #012970;
  font-weight: 700;
  margin: 0;
}

.features .feature-box i {
  line-height: 0;
  background: #ecf3ff;
  padding: 4px;
  margin-right: 10px;
  font-size: 24px;
  border-radius: 3px;
  transition: 0.3s;
}

.features .feature-box:hover i {
  background: #007CC2;
  color: #fff;
}

.features .feture-tabs {
  margin-top: 120px;
}

.features .feture-tabs h3 {
  color: #012970;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .features .feture-tabs h3 {
    font-size: 28px;
  }
}

.features .feture-tabs .nav-pills {
  border-bottom: 1px solid #eee;
}

.features .feture-tabs .nav-link {
  background: none;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  color: #012970;
  padding: 12px 0;
  margin-right: 25px;
  margin-bottom: -2px;
  border-radius: 0;
}

.features .feture-tabs .nav-link.active {
  color: #007CC2;
  border-bottom: 3px solid #007CC2;
}

.features .feture-tabs .tab-content h4 {
  font-size: 18px;
  margin: 0;
  font-weight: 700;
  color: #012970;
}

.features .feture-tabs .tab-content i {
  font-size: 24px;
  line-height: 0;
  margin-right: 8px;
  color: #007CC2;
}

.features .feature-icons {
  margin-top: 120px;
}

.features .feature-icons h3 {
  color: #012970;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .features .feature-icons h3 {
    font-size: 28px;
  }
}

.features .feature-icons .content .icon-box {
  display: flex;
}

.features .feature-icons .content .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 10px 0;
  color: #012970;
}

.features .feature-icons .content .icon-box i {
  font-size: 44px;
  line-height: 44px;
  color: #007CC2;
  margin-right: 15px;
}

.features .feature-icons .content .icon-box p {
  font-size: 15px;
  color: #848484;
}



/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials {
  padding: 80px 0;
  background: url("../public/assets/img/testimonials-bg.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}

.testimonials::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.testimonials .section-header {
  margin-bottom: 40px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  text-align: center;
  color: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 100px;
  border-radius: 50%;
  border: 6px solid rgba(255, 255, 255, 0.15);
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #fff;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #ddd;
  margin: 0 0 15px 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: rgba(255, 255, 255, 0.4);
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
  color: #eee;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative !important;
}

.swiper-pagination {
  position: relative !important;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #007CC2;
}

@media (min-width: 992px) {
  .testimonials .testimonial-item p {
    width: 80%;
  }
}


/*--------------------------------------------------------------
# Service Item
--------------------------------------------------------------*/
.service-item h6{
  color: #000000;
}

.stretched-link {
  color: #FFFFFF;
}

.service-item {
  margin-right: 50px;
  line-height: 1px;
}


/*--------------------------------------------------------------
# Misc Wrapper
--------------------------------------------------------------*/
.misc-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center
}

.misc-bg-wrapper {
  position: relative
}

.misc-bg-wrapper img {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: -1
}

@media(max-width: 1499.98px) {
  .misc-bg-wrapper img {
    height: 250px
  }

  .misc-under-maintenance-bg-wrapper img {
    height: 270px !important
  }
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #EB1D2C;
  border-top-color: #ffe9e3;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Recent Blog Posts
--------------------------------------------------------------*/
.recent-blog-posts .post-box {
  transition: 0.3s;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.recent-blog-posts .post-box .post-img {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}

.recent-blog-posts .post-box .post-img img {
  transition: 0.5s;
}

.recent-blog-posts .post-box .meta {
  margin-top: 15px;
}

.recent-blog-posts .post-box .meta .post-date {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-primary);
}

.recent-blog-posts .post-box .meta .post-author {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-secondary);
}

.recent-blog-posts .post-box .post-title {
  font-size: 24px;
  color: var(--color-secondary);
  font-weight: 700;
  margin: 15px 0 0 0;
  position: relative;
  transition: 0.3s;
}

.recent-blog-posts .post-box p {
  margin: 15px 0 0 0;
  color: rgba(var(--color-secondary-dark-rgb), 0.7);
}

.recent-blog-posts .post-box .readmore {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: 0.3s;
  margin-top: 15px;
}

.recent-blog-posts .post-box .readmore i {
  line-height: 0;
  margin-left: 4px;
  font-size: 18px;
}

.recent-blog-posts .post-box:hover .post-title {
  color: var(--color-primary);
}

.recent-blog-posts .post-box:hover .post-img img {
  transform: scale(1.1);
}


/*--------------------------------------------------------------
# Blog Stylings
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Blog Home Posts List
--------------------------------------------------------------*/
.blog .posts-list article {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  padding: 30px;
  height: 100%;
  border-radius: 10px

}

#blog p {
  color: #000000;
}

.blog .posts-list article+article {
  margin-top: 60px;
}

.blog .posts-list .post-img {
  max-height: 240px;
  margin: -30px -30px 0 -30px;
  overflow: hidden;
  border-radius: 10px 10px 0px 0px;
}

.blog .posts-list .title {
  font-size: 18px;
  font-weight: 700;
  padding: 0;
  margin: 20px 0 0 0;
}

.blog .posts-list .title a {
  color: var(--color-secondary);
  transition: 0.3s;
}

.blog .posts-list .title a:hover {
  color: var(--color-primary);
}

.blog .posts-list .meta-top {
  margin-top: 20px;
  color: var(--color-gray);
}

.blog .posts-list .meta-top ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .posts-list .meta-top ul li+li {
  padding-left: 20px;
}

.blog .posts-list .meta-top i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
  /* color: rgba(var(--color-primary-rgb), 0.8); */
  color: #000000 !important;
}

.blog .posts-list .meta-top a {
  color: var(--color-gray);
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog .posts-list .content {
  margin-top: 20px;
  color: #000000;
}

.blog .posts-list .read-more a {
  display: inline-block;
  background: #FFFFFF;
  color: #000000;
  padding: 8px 30px;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 4px;
}

.blog .posts-list .read-more a:hover {
  background: rgba(var(--color-primary-rgb), 0.8);
}

/*--------------------------------------------------------------
# Blog Details Page
--------------------------------------------------------------*/
.blog .blog-details {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.blog .blog-details .post-img {
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog .blog-details .title {
  font-size: 28px;
  font-weight: 700;
  padding: 0;
  margin: 20px 0 0 0;
  color: var(--color-secondary);
}

.blog .blog-details .content {
  margin-top: 20px;
}

.blog .blog-details .content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog .blog-details .content blockquote {
  overflow: hidden;
  background-color: rgba(var(--color-secondary-rgb), 0.06);
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .blog-details .content blockquote p {
  color: var(--color-default);
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog .blog-details .content blockquote:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: var(--color-secondary);
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .blog-details .meta-top {
  margin-top: 20px;
  color: var(--color-gray);
}

.blog .blog-details .meta-top ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .blog-details .meta-top ul li+li {
  padding-left: 20px;
}

.blog .blog-details .meta-top i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
  color: rgba(var(--color-primary-rgb), 0.8);
}

.blog .blog-details .meta-top a {
  color: var(--color-gray);
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog .blog-details .meta-bottom {
  padding-top: 10px;
  border-top: 1px solid rgba(var(--color-secondary-rgb), 0.15);
}

.blog .blog-details .meta-bottom i {
  color: var(--color-secondary-light);
  display: inline;
}

.blog .blog-details .meta-bottom a {
  color: rgba(var(--color-secondary-rgb), 0.8);
  transition: 0.3s;
}

.blog .blog-details .meta-bottom a:hover {
  color: var(--color-primary);
}

.blog .blog-details .meta-bottom .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog .blog-details .meta-bottom .cats li {
  display: inline-block;
}

.blog .blog-details .meta-bottom .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog .blog-details .meta-bottom .tags li {
  display: inline-block;
}

.blog .blog-details .meta-bottom .tags li+li::before {
  padding-right: 6px;
  color: var(--color-default);
  content: ",";
}

.blog .blog-details .meta-bottom .share {
  font-size: 16px;
}

.blog .blog-details .meta-bottom .share i {
  padding-left: 5px;
}

.blog .post-author {
  padding: 20px;
  margin-top: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .post-author img {
  max-width: 120px;
  margin-right: 20px;
}

.blog .post-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
  color: var(--color-secondary);
}

.blog .post-author .social-links {
  margin: 0 10px 10px 0;
}

.blog .post-author .social-links a {
  color: rgba(var(--color-secondary-rgb), 0.5);
  margin-right: 5px;
}

.blog .post-author p {
  font-style: italic;
  color: rgba(var(--color-gray-rgb), 0.8);
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Blog Sidebar
--------------------------------------------------------------*/
.blog .sidebar {
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, .1);
  border-radius: 10px;
}

.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0;
  margin: 0;
  color: var(--color-secondary);
}

.blog .sidebar .sidebar-item+.sidebar-item {
  margin-top: 40px;
}

.blog .sidebar .search-form form {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 3px 10px;
  position: relative;
}

.blog .sidebar .search-form form input[type=text] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
}

.blog .sidebar .search-form form input[type=text]:focus {
  outline: none;
}

.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: var(--color-primary);
  color: #FFFFFF;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}

.blog .sidebar .search-form form button i {
  line-height: 0;
}

.blog .sidebar .search-form form button i:hover {
  line-height: 0;
  color: #FFFFFF;
}

.blog .sidebar .search-form button i:hover {
  color: #FFFFFF !important;
}

.blog .sidebar .search-form button:hover {
  background: #EB1D2C;
}

.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .categories ul li+li {
  padding-top: 10px;
}

.blog .sidebar .categories ul a {
  color: var(--color-secondary);
  transition: 0.3s;
}

.blog .sidebar .categories ul a:hover {
  color: var(--color-default);
}

.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: rgba(var(--color-default-rgb), 0.4);
  font-size: 14px;
}

.blog .sidebar .recent-posts .post-item {
  display: flex;
}

.blog .sidebar .recent-posts .post-item+.post-item {
  margin-top: 15px;
}

.blog .sidebar .recent-posts img {
  width: 80px;
  margin-right: 15px;
}

.blog .sidebar .recent-posts h4 {
  font-size: 16px;
  font-weight: 400;
}

.blog .sidebar .recent-posts h4 a {
  color: var(--color-secondary);
  transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
  color: var(--color-primary);
}

.blog .sidebar .recent-posts time {
  display: block;
  font-style: italic;
  font-size: 14px;
  color: rgba(var(--color-default-rgb), 0.4);
}

.blog .sidebar .tags {
  margin-bottom: -10px;
}

.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .tags ul li {
  display: inline-block;
}

.blog .sidebar .tags ul a {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid rgba(0, 0, 0, 0.7);
  display: inline-block;
  transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
  color: #FFFFFF;
  border: 1px solid #007CC2;
  background: #007CC2;
}

.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: rgba(var(--color-secondary-light-rgb), 0.8);
  font-size: 14px;
}

/*--------------------------------------------------------------
# Blog Comments
--------------------------------------------------------------*/
.blog .comments {
  margin-top: 30px;
}

.blog .comments .comments-count {
  font-weight: bold;
}

.blog .comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .comments .comment .comment-img {
  margin-right: 14px;
}

.blog .comments .comment .comment-img img {
  width: 60px;
}

.blog .comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog .comments .comment h5 a {
  font-weight: bold;
  color: var(--color-default);
  transition: 0.3s;
}

.blog .comments .comment h5 a:hover {
  color: var(--color-primary);
}

.blog .comments .comment h5 .reply {
  padding-left: 10px;
  color: var(--color-secondary);
}

.blog .comments .comment h5 .reply i {
  font-size: 20px;
}

.blog .comments .comment time {
  display: block;
  font-size: 14px;
  color: rgba(var(--color-secondary-rgb), 0.8);
  margin-bottom: 5px;
}

.blog .comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 10.);
}

.blog .comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog .comments .reply-form p {
  font-size: 14px;
}

.blog .comments .reply-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .comments .reply-form input:focus {
  box-shadow: none;
  border-color: rgba(var(--color-primary-rgb), 0.8);
}

.blog .comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: rgba(var(--color-primary-rgb), 0.8);
}

.blog .comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .comments .reply-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: var(--color-secondary);
}

.blog .comments .reply-form .btn-primary:hover {
  background-color: rgba(var(--color-secondary-rgb), 0.8);
}

/*--------------------------------------------------------------
# Blog Home Pagination
--------------------------------------------------------------*/
.blog .blog-pagination {
  margin-top: 30px;
  color: var(--color-secondary-light);
}

.blog .blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog .blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}

.blog .blog-pagination li a {
  color: var(--color-secondary);
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
  background: var(--color-primary);
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
  color: #FFFFFF;
}










/********************************************************
About MobiPay
********************************************************/
a {
  background-color: rgba(0, 0, 0, 0);
}

a:active,
a:hover {
  outline: 0;
}

img {
  border: 0;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.w-button {
  color: #fff;
  line-height: inherit;
  cursor: pointer;
  background-color: #3898ec;
  border: 0;
  border-radius: 0;
  padding: 9px 15px;
  text-decoration: none;
  display: inline-block;
}

h2 {
  margin-bottom: 10px;
  font-weight: bold;
}

h2 {
  margin-top: 20px;
  font-size: 32px;
  line-height: 36px;
}

p {
  margin-top: 0;
  margin-bottom: 10px;
}

.w-container {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}

.w-container:before,
.w-container:after {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-container:after {
  clear: both;
}

@media screen and (max-width: 991px) {
  .w-container {
    max-width: 728px;
  }
}

@media screen and (max-width: 479px) {
  .w-container {
    max-width: none;
  }
}

h2 {
  color: var(--neutral-800);
  letter-spacing: .02em;
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.35em;
}

p {
  margin-bottom: 24px;
}

a {
  color: #EB1D2C;
  text-decoration: underline;
  transition: color .3s;
}

a:hover {
  color: var(--secondary-3);
}

img {
  max-width: 100%;
  display: inline-block;
}

.container-default {
  max-width: 1290px;
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.button-primary {
  background-color: #EB1D2C;
  box-shadow: 0 8px 22px 0 var(--shadow-button-primary);
  color: #FFFFFF;
  text-align: center;
  transform-style: preserve-3d;
  border-radius: 5px;
  padding: 15px 40px;
  line-height: 1.111em;
  transition: transform .3s, box-shadow .3s;
  min-width: 200px;
}

.button-primary:hover {
  color: #007CC2;
  background-color: transparent;
  border: 1px solid rgb(0, 124, 194);
  transform: translate3d(0, -6px, .01px);
  box-shadow: 0 15px 32px rgba(23, 136, 228, 0.38);
}

.section {
  padding-top: 100px;
  padding-bottom: 100px;
}

.about-background-image {
  width: 100%;
  height: auto;  /* Ensure it scales proportionally */
  display: block;  /* Make sure it stays in the block flow */
}

.about {
  /* background: url("../public/assets/img/bg-23.png") top center; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.title.about {
  margin-bottom: 24px;
  margin-right: 10px;
}

.split-content.home-v3-about-content {
  z-index: 1;
  max-width: 42%;
  position: relative;
}

.image.home-v3-about-1 {
  border-radius: 20px;
  box-shadow: 0 24px 34px rgba(20, 20, 43, .08), 0 6px 28px rgba(63, 128, 253, .14);
}

.image.home-v3-about-2 {
  border-radius: 20px;
  margin-bottom: 24px;
  box-shadow: 0 24px 34px rgba(20, 20, 43, .08), 0 6px 28px rgba(63, 128, 253, .14);
}

.image.home-v3-about-3 {
  border-radius: 20px;
  box-shadow: 0 24px 34px rgba(20, 20, 43, .08), 0 6px 28px rgba(63, 128, 253, .14);
}

.subtitle-wrapper {
  color: #EB1D2C;
  letter-spacing: .02em;
  align-items: center;
  margin-bottom: 16px;
  font-weight: 700;
  line-height: 1.111em;
  display: flex;
}

.subtitle-shape {
  width: 22px;
  max-height: 2px;
  min-height: 2px;
  /* background-color: #EB1D2C; */
  background: linear-gradient(90deg, rgba(0, 123, 194, 1) 0%, rgba(235, 29, 44, 1) 100%);
  border-radius: 1000px;
  margin-right: 10px;
}

.bg {
  position: absolute;
}

.bg.home-v3-about {
  max-width: 138%;
}

.home-v3-about-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.home-v3-about-images-wrapper {
  width: 100%;
  max-width: 663px;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
  display: flex;
  position: relative;
}

.home-v3-about-images-left {
  z-index: 1;
  width: 100%;
  max-width: 320px;
  flex-direction: column;
  align-self: stretch;
  margin-right: 24px;
  display: flex;
  position: relative;
}

.home-v3-about-images-right {
  z-index: 1;
  width: 100%;
  max-width: 320px;
  flex-direction: column;
  display: flex;
  position: relative;
}

.button-primary-arrow {
  font-family: "Comfortaa", "Open Sans";
  font-size: 13px;
  font-weight: 400;
  line-height: 1em;
  display: inline-block;
  position: relative;
  top: -2px;
}

@media screen and (max-width: 991px) {
  .container-default {
    flex-direction: column;
    align-items: stretch;
  }

  .button-primary {
    padding-left: 40px;
    padding-right: 40px;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .paragraph.home-v3-about {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .title.home-v3-about {
    margin-bottom: 20px;
    margin-right: 0;
  }

  .split-content.home-v3-about-content {
    max-width: 616px;
    text-align: center;
    margin-bottom: 80px;
  }

  .subtitle-wrapper {
    margin-bottom: 13px;
  }

  .subtitle-wrapper.home-v3-about {
    justify-content: center;
  }

  .subtitle-shape.home-v3-about {
    display: none;
  }

  .home-v3-about-wrapper {
    flex-direction: column-reverse;
    align-items: center;
  }

  .home-v3-about-images-wrapper {
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  h2 {
    margin-bottom: 13px;
    font-size: 33px;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .paragraph.home-v3-about {
    max-width: 647px;
    margin-left: 0;
  }

  .title.home-v3-about {
    max-width: 575px;
    margin-bottom: 18px;
  }

  .split-content.home-v3-about-content {
    /* max-width: 100%;
    text-align: left;
    margin-bottom: 60px; */

    max-width: 616px;
    justify-content: center;
    text-align: center;
    margin-bottom: 80px;
  }

  .image.home-v3-about-1 {
    border-radius: 20px;
  }

  .image.home-v3-about-2 {
    border-radius: 30px;
    margin-bottom: 20px;
  }

  .image.home-v3-about-3 {
    border-radius: 30px;
  }

  .subtitle-wrapper {
    margin-bottom: 10px;
  }

  .subtitle-wrapper.home-v3-about {
    justify-content: center;
  }

  .subtitle-shape.home-v3-about {
    display: block;
  }

  .home-v3-about-wrapper {
    align-items: stretch;
  }

  .home-v3-about-images-wrapper {
    max-width: 100%;
  }

  .home-v3-about-images-left {
    max-width: 50%;
    margin-right: 20px;
  }

  .home-v3-about-images-right {
    max-width: 50%;
  }
}

@media screen and (max-width: 479px) {
  h2 {
    margin-bottom: 10px;
    font-size: 28px;
  }

  .container-default {
    padding-left: 16px;
    padding-right: 16px;
  }

  .btn-primary {
    padding: 15px 40px;
    font-size: 16px;
    display: block;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .title.home-v3-about {
    margin-bottom: 14px;
  }

  .split-content.home-v3-about-content {
    margin-bottom: 50px;
  }

  .image.home-v3-about-1,
  .image.home-v3-about-2,
  .image.home-v3-about-3 {
    border-radius: 20px;
  }

  .subtitle-wrapper {
    justify-content: flex-start;
  }

  .subtitle-shape {
    display: block;
  }
}


.section.home-v2-about {
  padding-bottom: 176px;
  overflow: hidden;
}

.paragraph.home-v2-about {
  margin-bottom: 40px;
}

.split-content.home-v2-about-content {
  max-width: 42%;
}

.image.home-v2-about-1 {
  max-width: 60%;
  border-radius: 30px;
}

.image.home-v2-about-2 {
  max-width: 75%;
  border-radius: 30px;
  align-self: flex-end;
  margin-top: -213px;
  box-shadow: 0 18px 84px rgba(20, 20, 43, .14);
}

.subtitle-wrapper {
  color: var(--primary-1);
  letter-spacing: .02em;
  align-items: center;
  margin-bottom: 16px;
  font-weight: 700;
  line-height: 1.111em;
  display: flex;
}

.subtitle-shape {
  width: 22px;
  max-height: 2px;
  min-height: 2px;
  background-color: var(--primary-1);
  border-radius: 1000px;
  margin-right: 10px;
}

.bg {
  position: absolute;
}

.bg.home-v2-about {
  z-index: -1;
}

.home-v2-about-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.home-v2-about-images-wrapper {
  width: 100%;
  max-width: 657px;
  flex-direction: column;
  margin-right: 40px;
  display: flex;
  position: relative;
}







/***************************** 
Landing Page Hero Section
*****************************/
#landing-hero {
  background-size: cover;
  /* position: relative; */
  padding-top: 100px;
  padding-bottom: 100px;
}

img {
  border: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
}

.overflow {
  overflow: visible !important;
}

.bg-image {
  max-width: 50% !important;
  height: 100%;
  object-fit: cover;
}

.about-image {
  max-width: 100%;
  height: auto;
  border-radius: 20px;
  margin-bottom: 20px;
  z-index: 1;
}

.landing-hero-image-1 {
  max-width: 70% !important;
  height: auto;
  border-radius: 30px;
  margin-bottom: 20px;
}

.landing-hero-image {
  max-width: 100%;
  height: auto;
  border-radius: 30px;
  margin-bottom: 20px;
}

.retail-features-image {
  margin-left: 50px; 
  max-width: 120%; 
  border-radius: 30px; 
}

.features-image {
  margin-left: -150px;  
  max-width: 120%; 
  border-radius: 30px;  
}


@media (max-width: 991px) {
  .features-image, .retail-features-image {
    margin-left: 0; 
    max-width: 100%;
  }
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.w-container {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}

.w-container:before,
.w-container:after {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-container:after {
  clear: both;
}

@media screen and (max-width: 991px) {
  .w-container {
    max-width: 728px;
  }
}

@media screen and (max-width: 479px) {
  .w-container {
    max-width: none;
  }
}

.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

/* .w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
} */

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
    max-width: none;
  }
}

h1 {
  color: var(--components--titles-paragraphs--text-titles);
  margin-top: 0;
  margin-bottom: 0;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.112em;
}

h2 {
  color: var(--core--colors--neutral--800);
  margin-top: 0;
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2em;
}

p {
  margin-bottom: 0;
}

a {
  color: var(--core--colors--neutral--800);
  text-decoration: underline;
  transition: color .3s;
}

a:hover {
  color: var(--core--colors--neutral--600);
}

img {
  max-width: 100%;
  display: inline-block;
}

.display-12 {
  font-size: 70px;
  line-height: 1.228em;
}

.semi-bold {
  font-weight: 600;
}

.display-10 {
  font-size: 52px;
  line-height: 1.192em;
}

.display-5 {
  font-size: 22px;
  line-height: 1.4em;
}

.mg-top-24px {
  margin-top: 24px;
}

.grid-2-columns {
  grid-column-gap: 22px;
  grid-row-gap: 22px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.grid-2-columns.hero-v1-main-grid {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  grid-template-columns: 3.1fr .8fr;
  align-items: center;
}

.grid-2-columns.hero-v1-grid-left {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  grid-template-columns: 1.3fr .8fr;
  align-items: end;
  justify-items: stretch;
}

.grid-2-columns.hero-v1-grid-left-bottom {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  width: 100%;
  max-width: 918px;
  padding-left: 40px;
}

/* .card {
  border: 1px solid var(--core--colors--neutral--400);
  background-color: var(--core--colors--neutral--100);
  box-shadow: 0 1px 4px 0 var(--neutral-shadow--02);
  color: var(--core--colors--neutral--700);
  border-radius: 24px;
  overflow: hidden;
} */

.card.hero-card {
  background-color: var(--core--colors--primary--100);
  color: var(--core--colors--neutral--100);
  border-style: none;
  padding: 32px;
}

.text-neutral-100 {
  color: var(--core--colors--neutral--100);
}

.flex-horizontal {
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-horizontal.justify-start {
  justify-content: flex-start;
}

.grid-1-column {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.grid-1-column.hero-v1-grid-right {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
}

.grid-1-column.hero-v1-grid-content {
  grid-row-gap: 32px;
}

.grid-1-column.hero-v1-grid-left-right {
  grid-row-gap: 32px;
}

.inner-container {
  max-width: 1200px;
}

.inner-container._246px {
  max-width: 246px;
}

.icon-font-rounded {
  font-family: "Comfortaa", "Open Sans";
}

/* .button-primary {
  padding: var(--components--buttons--paddings--pd-regular) var(--components--buttons--paddings--pd-medium);
  border: 1px solid var(--components--buttons--backgrounds--bg-color-accent);
  border-radius: var(--components--buttons--border-radius--br-pill);
  background-color: var(--components--buttons--backgrounds--bg-color-accent);
  box-shadow: 0 1px 4px 0 var(--neutral-shadow--02);
  color: var(--components--buttons--text--text-light);
  font-size: var(--core--font-size--displays--display-2);
  line-height: var(--core--line-height--regular);
  text-align: center;
  transform-style: preserve-3d;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  text-decoration: none;
  transition: color .3s, border-color .3s, background-color .3s, transform .3s;
  display: flex;
} */

.button-primary:hover {
  border-color: var(--core--colors--primary--100);
  background-color: var(--core--colors--primary--100);
  color: var(--neutral--100\<deleted\|variable-89a718ad\>);
  transform: scale3d(1.04, 1.04, 1.01);
}

.button-primary.small {
  border-radius: var(--components--buttons--border-radius--br-pill);
  padding: 8px 12px;
  font-size: 12px;
  line-height: 1.285em;
}

.button-primary.white,
.button-primary.white:hover {
  border-color: var(--core--colors--neutral--100);
  background-color: var(--core--colors--neutral--100);
  color: var(--core--colors--primary--100);
}

.item-icon-right {
  margin-left: 4px;
}

.paragraph-large {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.556em;
}

.button-row {
  grid-column-gap: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.about-button-row {
  grid-column-gap: 16px !important;
  justify-content: start;
  align-items: start;
  display: flex;
}

.buttons-row.left {
  justify-content: flex-start;
}

.image-wrapper {
  overflow: hidden;
  border-radius: 25px;
}

.mg-bottom-4px {
  margin-bottom: 4px;
}

.container-default {
  padding-right: var(--core--spacing--container-default--padding--default);
  padding-left: var(--core--spacing--container-default--padding--default);
  max-width: 1268px;
  margin-left: auto;
  margin-right: auto;
}

.section {
  padding-top: 100px;
  padding-bottom: 100px;
}

.section.hero {
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.section.hero.v1 {
  padding-top: 42px;
  padding-bottom: 112px;
  /* background: url("../public/assets/img/hero-bg-4.png") center; */
}

.divider-details {
  background-color: var(--core--colors--neutral--400);
  width: 22px;
  height: 1px;
  margin-left: 16px;
  margin-right: 16px;
}

.divider-details.small {
  width: 12px;
  margin-left: 8px;
  margin-right: 8px;
}

.divider-details.white {
  background-color: rgba(255, 255, 255, .6);
}

.border-radius-24px {
  border-radius: 24px;
}

._w-h-100 {
  width: 100%;
  height: 100%;
}

._w-h-100.fit-cover {
  object-fit: cover;
}

@media screen and (max-width: 991px) {
  .display-12 {
    font-size: 64px;
  }

  .display-10 {
    font-size: 48px;
  }

  .display-5 {
    font-size: 20px;
  }

  .grid-2-columns.hero-v1-main-grid {
    grid-template-columns: 3.1fr;
  }

  .grid-2-columns.hero-v1-grid-left-bottom {
    padding-left: 0;
  }

  .grid-1-column.hero-v1-grid-right {
    grid-template-columns: 1fr 1fr;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 767px) {

  .gradient-text {
    text-align: center;
    align-self: center;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 26px;
  }

  .display-12 {
    font-size: 48px;
  }

  .display-10 {
    font-size: 36px;
  }

  .mg-top-24px {
    margin-top: 20px;
  }

  .grid-2-columns {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.hero-v1-grid-left {
    grid-row-gap: 20px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.hero-v1-grid-left-bottom {
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr;
  }

  .card {
    border-radius: 16px;
  }

  .grid-1-column.hero-v1-grid-right {
    grid-column-gap: 20px;
  }

  .grid-1-column.hero-v1-grid-left-right {
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr;
  }

  .inner-container._100-mbl {
    max-width: 100%;
  }

  .paragraph-large {
    font-size: 16px;
  }

  .image-wrapper {
    border-radius: 10px;
  }

  .container-default {
    padding-right: var(--core--spacing--container-default--padding--mb);
    padding-left: var(--core--spacing--container-default--padding--mb);
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 479px) {
  .display-12 {
    font-size: 42px;
  }

  .display-10 {
    font-size: 32px;
  }

  .grid-2-columns.hero-v1-main-grid {
    grid-row-gap: 16px;
  }

  .grid-2-columns.hero-v1-grid-left {
    grid-row-gap: 24px;
  }

  .grid-2-columns.hero-v1-grid-left-bottom {
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }

  .card {
    overflow: hidden;
  }

  .card.hero-card {
    padding-left: 24px;
    padding-right: 24px;
  }

  .grid-1-column.hero-v1-grid-right,
  .grid-1-column.hero-v1-grid-left-right {
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }

  .button-primary {
    width: 100%;
  }

  .buttons-row {
    grid-row-gap: 14px;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }

  .image-wrapper {
    border-radius: 10px;
  }

  .container-default {
    padding-left: 18px;
    padding-right: 18px;
  }
}

#w-node-_9c5715ba-118b-88e1-b97d-7bbaf8e6285e-7809effb {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#landing-hero-image-1 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#landing-hero-image-2 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#landing-hero-image-3 {
  grid-area: span 1 / span 2 / span 1 / span 2;
  justify-self: end;
}

#_grid-area,
#w-node-cd51af6f-23d4-6ba7-be1d-0070dacbd685-7809effb {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_95430d12-e062-5a9a-db4a-70e36fb22663-6fb22663 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

@media screen and (max-width: 767px) {
  #landing-hero-image-3 {
    grid-area: span 1 / span 1 / span 1 / span 1;
    justify-self: end;
  }
}


/******************************************
Landing Page Hero
*****************************************/
.hero-section {
  position: relative;
  background: linear-gradient(to right, #69bae9, #e7616a, #69bae9);
  padding: 60px 0;
  overflow: hidden;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  /* Copy the gradient background */
  filter: blur(10px);
  /* Adjust blur intensity */
  z-index: 1;
  /* Make sure the blur layer is below content */
}

.hero-section .container {
  position: relative;
  z-index: 2;
  /* Ensure the content is above the blur */
}

.hero-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #000;
}

.hero-btn {
  background-color: #ff3b30;
  color: #fff;
  font-weight: bold;
  border-radius: 4px;
  padding: 10px 20px;
  margin-right: 15px;
}

.rounded-img {
  border-radius: 24px;
}





/***********************************************

***********************************************/
.grid-2-columns {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  /* align-items: center; */
  display: grid;
}

.grid-2-columns._1fr---3fr {
  grid-template-columns: 1fr 3fr;
  margin-bottom: 50px;
}

.grid-2-columns._2fr---1fr {
  grid-template-columns: 2fr 1fr;
}

.grid-2-columns.gap-row-130px {
  grid-row-gap: 20px;
}

.color-neutral-800 {
  color: var(--neutral--800);
}

.color-neutral-700 {
  color: var(--neutral--700);
}

.color-neutral-600 {
  color: var(--neutral--600);
}

.mg-bottom-16px {
  margin-bottom: 16px;
}

.gap-column-80px {
  grid-column-gap: 80px;
}

.display-2 {
  color: var(--neutral--800);
  font-size: 48px !important;
  font-weight: 400;
  line-height: 1.231em;
}

.display-2.color-neutral-600 {
  color: #626262;
}

.display-3 {
  color: #626262;
  font-size: 32px !important;
  font-weight: 400;
  line-height: 1.406em;
}

.inner-container._544px {
  max-width: 544px;
}

.mg-bottom-0 {
  margin-bottom: 0;
}

.btn-primary {
  background-color: var(--neutral--800);
  color: var(--neutral--100);
  text-align: center;
  letter-spacing: .04em;
  text-transform: uppercase;
  transform-style: preserve-3d;
  border-radius: 12px;
  justify-content: center;
  padding: 22px 28px;
  font-size: 14px;
  line-height: 1.143em;
  text-decoration: none;
  transition: background-color .3s, transform .3s, color .3s;
}

.btn-primary:hover {
  background-color: var(--neutral--700);
  color: var(--neutral--100);
  transform: translate3d(0, -5px, .01px);
}

.container-default {
  max-width: 1316px;
  padding-left: 24px;
  padding-right: 24px;
}

.section {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pd-left-44px {
  padding-left: 44px;
}

.border-left---neutral-800-2px {
  border-left: 2px solid #626262;
}

.flex {
  display: flex;
}

.flex.align-end {
  align-items: flex-end;
}

.mg-left-2px {
  margin-left: 2px;
}

.pd-left-68px {
  padding-left: 68px;
}

.border-left---neutral-400-2px {
  border-left: 2px solid #8b8b8b;
}

.mg-bottom-110px {
  margin-bottom: 110px;
}

.font-size-164px {
  font-size: 164px;
  line-height: 1.049em;
}

.mg-bottom--10px {
  margin-bottom: -10px;
}

@media screen and (max-width: 991px) {
  h2 {
    margin-bottom: 12px;
    font-size: 30px;
  }

  .grid-2-columns._1-col-tablet {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.gap-column-80px {
    grid-column-gap: 40px;
  }

  .grid-2-columns._1fr---3fr._1-col-tablet {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.gap-row-130px {
    grid-row-gap: 100px;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .hidden-on-tablet {
    display: none;
  }

  .pd-left-68px.border-left---neutral-400-2px.border-and-padding-none-tablet {
    border-left-style: none;
    padding-left: 0;
  }

  .font-size-164px {
    font-size: 100px;
  }
}

@media screen and (max-width: 767px) {
  h2 {
    font-size: 26px;
  }

  .grid-2-columns {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.gap-column-80px {
    grid-row-gap: 48px;
  }

  .grid-2-columns._1fr---3fr,
  .grid-2-columns._2fr---1fr {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.gap-row-130px {
    grid-row-gap: 48px;
  }

  .display-2 {
    font-size: 40px;
  }

  .display-3 {
    font-size: 26px;
  }

  .inner-container._100-mbl {
    max-width: 100%;
  }

  .btn-primary {
    padding: 20px 24px;
  }

  .container-default {
    padding-left: 20px;
    padding-right: 20px;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .pd-left-44px {
    padding-left: 32px;
  }

  .mg-bottom-110px {
    margin-bottom: 80px;
  }

  .font-size-164px {
    font-size: 80px;
  }

  .pd-left-24px-mbl {
    padding-left: 24px;
  }

  .mg-bottom-6px-mbl {
    margin-bottom: 6px;
  }
}

@media screen and (max-width: 479px) {
  .mg-bottom-16px {
    margin-bottom: 8px;
  }

  .display-2 {
    font-size: 36px;
  }

  .display-3 {
    font-size: 24px;
  }

  .btn-primary {
    width: 100%;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .container-default {
    padding-left: 16px;
    padding-right: 16px;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .pd-left-44px {
    padding-left: 24px;
  }

  .mg-left-2px.mg-bottom-16px.mg-bottom-6px-mbl {
    margin-bottom: 4px;
  }

  .mg-bottom-110px {
    margin-bottom: 72px;
  }

  .font-size-164px {
    font-size: 56px;
  }
}

#w-node-e86c4972-2756-4b8d-fcc0-35840dc18a94-532b2890 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_77ee5f1e-0f7d-ef73-0e2e-242f3016513e-532b2890 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: end;
  justify-self: end;
}

@media screen and (max-width: 767px) {
  #w-node-_77ee5f1e-0f7d-ef73-0e2e-242f3016513e-532b2890 {
    justify-self: start;
  }
}
















/**************************************

*************************************/

section {
  display: block;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

a:active,
a:hover {
  outline: 0;
}

* {
  box-sizing: border-box;
}

h2 {
  margin-bottom: 10px;
  font-weight: bold;
}

h2 {
  margin-top: 20px;
  font-size: 32px;
  line-height: 36px;
}

.w-container {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}

.w-container:before,
.w-container:after {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-container:after {
  clear: both;
}

@media screen and (max-width: 991px) {
  .w-container {
    max-width: 728px;
  }
}

@media screen and (max-width: 479px) {
  .w-container {
    max-width: none;
  }
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

h2 {
  color: var(--neutral--800);
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 38px;
  font-weight: 600;
  line-height: 1.316em;
}

a {
  color: var(--accent--primary-1);
  text-decoration: underline;
  transition: color .3s;
}

a:hover {
  color: var(--neutral--600);
}

.grid-2-columns {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  /* align-items: center; */
  display: grid;
}

.grid-2-columns.title-and-buttons {
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  grid-template-columns: 1fr auto;
}

.color-neutral-200 {
  color: var(--neutral--200);
}

.color-neutral-100 {
  color: var(--neutral--100);
}

.mg-bottom-16px {
  margin-bottom: 16px;
}

.display-2 {
  color: var(--neutral--800);
  font-size: 60px;
  font-weight: 600;
  line-height: 1.233em;
}

.display-2.color-neutral-100 {
  color: var(--neutral--100);
}

.inner-container.center {
  margin-left: auto;
  margin-right: auto;
}

.inner-container._745px {
  max-width: 745px;
}

.mg-bottom-0 {
  margin-bottom: 0;
}

.position-relative {
  position: relative;
}

.buttons-row {
  align-items: center;
  display: flex;
}

.container-default {
  max-width: 1268px;
  padding-left: 24px;
  padding-right: 24px;
}


.section._0px {
  padding-top: 0;
  padding-bottom: 0;
}

.flex-horizontal {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-horizontal.start {
  justify-content: flex-start;
}

.flex-horizontal.start.gap-12px {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
}

.flex-horizontal.gap-12px {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
}

.cta-section {
  /* background-color: #000000; */
  background: linear-gradient(90deg, rgba(0, 123, 194, 1) 0%, rgba(235, 29, 44, 1) 100%);
  margin-left: auto;
  margin-right: auto;
  padding: 40px 50px;
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  margin-bottom: 50px;
  box-shadow: 0 15px 32px rgba(23, 136, 228, 0.38);
}

.z-index-1 {
  z-index: 1;
  position: relative;
}

.line-sustitle {
  width: 28px;
  min-height: 1px;
  background-color: var(--neutral--800);
}

.line-sustitle.line-white {
  background-color: var(--neutral--100);
}

.sustitle {
  color: var(--neutral--800);
  letter-spacing: .06em;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.125em;
}

.sustitle.color-neutral-200 {
  color: var(--neutral--200);
}

.bg-shape {
  width: 100%;
  height: 100%;
  /* background-image: url("https://assets-global.website-files.com/638f55881d235eb451c819e1/63992f800fdb285f4422f2be_cta-bg-decorative-carpenters-x-webflow-template.svg"); */
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.bg-shape.bg-cta-v2 {
  /* background-image: url("https://assets-global.website-files.com/638f55881d235eb451c819e1/6390eec7264b7c2eac8f99b0_cta-v2-bg-decorative-carpenters-x-webflow-template.svg"); */
  background-position: 50% 0;
  background-size: cover;
}

.section-cta-v2-gradient {
  /* background-image: linear-gradient(to bottom, var(--neutral--200) 50%, white 50%); */
  /* background: linear-gradient(90deg, rgba(235, 29, 44, 1 ) 0%, rgba(0, 123, 194, 1) 100%); */
  /* background-color: #f5f7fc; */
  background: url("../public/assets/img/bg-23.png") top center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* /* height: 100vh; */
  /* background-color: #eeeeee; */
  overflow: hidden;
}

.section-cta-v3-gradient {
  background: url("../public/assets/img/about-us-4.png") top center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.show-in-tablet {
  display: none;
}

@media screen and (min-width: 1440px) {
  .section._0px {
    padding-top: 0;
    padding-bottom: 0;
  }


}

@media screen and (min-width: 1920px) {
  .section._0px {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 991px) {
  h2 {
    margin-bottom: 12px;
    font-size: 30px;
  }

  .grid-2-columns._1-col-tablet {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.title-and-buttons._1-col-tablet {
    grid-template-columns: 1fr;
  }

  .display-2 {
    font-size: 48px;
  }

  .inner-container._100---tablet {
    max-width: 100%;
  }

  .inner-container._650px---tablet {
    max-width: 650px;
  }

  .buttons-row.center---tablet {
    justify-content: center;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section._0px {
    padding-top: 0;
    padding-bottom: 0;
  }

  .flex-horizontal.start.gap-12px.mg-bottom-16px.center---tablet,
  .flex-horizontal.start.center---tablet {
    justify-content: center;
  }

  .cta-section {
    text-align: center;
    padding: 125px 60px;
  }

  .show-in-tablet {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  h2 {
    font-size: 26px;
  }

  .grid-2-columns {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.title-and-buttons {
    grid-template-columns: 1fr;
  }

  .display-2 {
    font-size: 36px;
  }

  .inner-container._500px---mbl {
    max-width: 500px;
  }

  .btn-primary {
    padding: 22px 32px;
    font-size: 16px;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section._0px {
    padding-top: 0;
    padding-bottom: 0;
  }

  .cta-section {
    padding: 100px 34px;
  }

  .sustitle {
    font-size: 14px;
  }
}

@media screen and (max-width: 479px) {
  h2 {
    font-size: 22px;
  }

  .mg-bottom-16px {
    margin-bottom: 8px;
  }

  .display-2 {
    font-size: 32px;
  }

  .btn-primary {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .buttons-row {
    flex-direction: column;
  }

  .section {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .section._0px {
    padding-top: 0;
    padding-bottom: 0;
  }

  .cta-section {
    padding: 50px 24px;
  }
}

#w-node-_9999d73d-81c0-b7d4-7858-484ec6c5b430-c6c5b425 {
  align-self: center;
  justify-self: end;
}

@media screen and (max-width: 991px) {
  #w-node-_9999d73d-81c0-b7d4-7858-484ec6c5b430-c6c5b425 {
    justify-self: auto;
  }
}

@media screen and (max-width: 767px) {
  #w-node-_9999d73d-81c0-b7d4-7858-484ec6c5b430-c6c5b425 {
    align-self: auto;
    justify-self: auto;
  }
}
































.display-4 {
  color: var(--neutral--800);
  font-size: 28px;
  font-weight: 700;
  line-height: 1.429em;
}

.inner-container._320px {
  max-width: 320px;
}

.line-rounded-icon {
  font-family: Line Rounded Icons, sans-serif;
}

.line-rounded-icon.link-icon-right {
  margin-left: 6px;
  color: #626262;
  font-weight: 800;
  display: inline-block;
}

.link-wrapper {
  color: var(--neutral--800);
  transition: none;
  display: inline-block;
}

.link-wrapper:hover {
  color: var(--accent--primary-1);
}

.link-wrapper.card-view-more-link {
  color: var(--accent--primary-1);
  font-weight: 700;
}

.link-text {
  font-family: 'Comfortaa';
  display: inline-block;
  color: #626262;
  font-weight: 800;
  font-size: 14px;
}

.fit-cover {
  object-fit: cover;
}

.image {
  width: 100%;
  height: auto;
}

.slide-item-mg {
  margin-right: 28px;
}

.slide-item-mg.slide-card-wrapper {
  height: 100%;
}

.mg-bottom-4px {
  margin-bottom: 4px;
}

.flex-shrink {
  flex: 0 auto;
}

.icon-wrapper {
  border-radius: 50%;
  transform: translate(0);
}

.icon-wrapper._60px {
  width: 60px;
  height: 60px;
  max-height: 60px;
  max-width: 60px;
}

.service-card-wrapper {
  max-width: 1010px;
  min-height: 100%;
  color: var(--neutral--600);
  flex-direction: column;
  margin-left: auto;
  text-decoration: none;
  display: flex;
}

.service-card-wrapper:hover {
  color: var(--neutral--600);
}

.service-card-image-wrapper {
  max-width: 75%;
  border-radius: 15px;
  /* margin-bottom: -116px; */
  margin-bottom: -100px;
  margin-left: auto;
  overflow: hidden;
  transform: translate(0);
}

.service-card-content {
  z-index: 1;
  width: 100%;
  max-width: 480px;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  border: 1px solid #FFFFFF;
  background-color: #FFFFFF;
  border-radius: 15px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: auto;
  padding: 30px 30px;
  display: flex;
  position: relative;
  transform: translate(0);
  box-shadow: 0 15px 32px rgba(23, 136, 228, 0.38);
}

.rounded-icon---bg {
  background-color: #007CC2;
  border-radius: 50%;
}

@media screen and (max-width: 991px) {
  .display-4 {
    font-size: 24px;
  }
}

@media screen and (max-width: 767px) {
  h3 {
    font-size: 22px;
  }

  .service-card-image-wrapper {
    max-height: 500px;
    border-radius: 20px;
    margin-bottom: -100px;
    margin-left: 0;
  }

  .service-card-content {
    max-width: 600px;
    border-radius: 20px;
    margin-left: auto;
  }
}

@media screen and (max-width: 479px) {
  .inner-container._320px._100-mbp {
    max-width: 100%;
  }

  .service-card-image-wrapper {
    max-width: 100%;
    border-radius: 14px 14px 0 0;
    margin-bottom: 0;
  }

  .service-card-content {
    width: 100%;
    max-width: none;
    grid-row-gap: 16px;
    border-radius: 0 0 14px 14px;
    flex-direction: column;
    margin-top: 0;
    margin-left: 0;
    padding-top: 32px;
    padding-left: 24px;
    padding-right: 24px;
    box-shadow: none;
  }
}

/*! CSS Used fontfaces */
@font-face {
  font-family: "Comfortaa", "Open Sans";
  src: url('https://assets.website-files.com/637251dd2659f1be53baa587/637251dd2659f105edbaa62d_line-rounded-icons.woff2') format('woff2'), url('https://assets.website-files.com/637251dd2659f1be53baa587/637251dd2659f16022baa5fd_line-rounded-icons.eot') format('embedded-opentype'), url('https://assets.website-files.com/637251dd2659f1be53baa587/637251dd2659f169fabaa5e2_line-rounded-icons.woff') format('woff'), url('https://assets.website-files.com/637251dd2659f1be53baa587/637251dd2659f1624ebaa5f9_line-rounded-icons.ttf') format('truetype'), url('https://assets.website-files.com/637251dd2659f1be53baa587/637251dd2659f1d797baa5fb_line-rounded-icons.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}



/*
News Articles
*/
.badge-wrapper {
  z-index: 1;
  position: absolute;
}

.badge-wrapper.bottom-left-32px {
  bottom: 32px;
  left: 32px;
}

.badge-wrapper.bottom-left-32px {
  bottom: 16px;
  left: 16px;
}

.badge-secondary {
  border: 1px solid var(--neutral--400);
  background-color: var(--neutral--100);
  box-shadow: 0 2px 12px 0 var(--general--shadow-01);
  color: var(--neutral--800);
  text-align: center;
  letter-spacing: .06em;
  text-transform: uppercase;
  border-radius: 40px;
  padding: 18px 28px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.143em;
  text-decoration: none;
  display: inline-block;
}

.badge-secondary.white {
  border-color: var(--neutral--100);
  box-shadow: none;
  color: var(--neutral--100);
  background-color: rgba(0, 0, 0, 0);
}

.badge-secondary.light {
  background-color: var(--neutral--200);
  box-shadow: none;
  border-width: 0;
}

.badge-secondary.small {
  padding: 10px 16px;
}

.badge-secondary.large {
  padding: 22px 38px;
  font-size: 16px;
}

.fit-cover {
  object-fit: cover;
}

.fit-cover.position-left {
  object-position: 0% 50%;
}

.fit-cover.position-top {
  object-position: 50% 0%;
}

.border-radius-40px {
  border-radius: 32px;
}

.border-radius-40px {
  border-radius: 24px;
}

.border-radius-40px {
  border-radius: 40px;
  transform: translate(0);
}

.mg-bottom-24px-mbl {
  margin-bottom: 24px;
}

.link-wrapper {
  color: var(--neutral--800);
  display: inline-block;
}

.link-wrapper:hover {
  color: var(--neutral--600);
}

.link-wrapper.white {
  color: var(--neutral--100);
}

.link-wrapper.white:hover {
  color: var(--neutral--600);
}

.link-wrapper.white.sibling-opacity-item:hover {
  color: var(--neutral--100);
}

.link-wrapper.hover-none:hover {
  color: var(--neutral--800);
}

.link-wrapper.color-neutral-300 {
  color: var(--neutral--300);
}

.link-wrapper.color-neutral-300:hover {
  color: var(--neutral--100);
}

.display-4 {
  color: var(--neutral--800);
  font-size: 28px;
  font-weight: 400;
  line-height: 1.286em;
}

.display-4.color-neutral-100 {
  color: #FFFFFF !important;
}

.display-4.color-neutral-600 {
  color: var(--neutral--600);
}

.display-4 {
  font-size: 12px;
}

.display-4 {
  font-size: 20px;
}

.mg-bottom-56px.mg-bottom-32px-tablet {
  margin-bottom: 32px;
}

.mg-bottom-32px {
  margin-bottom: 32px;
}

.display-4.mg-bottom-32px.mg-bottom-8px-mbl {
  margin-bottom: 8px;
}

.display-4.mg-bottom-32px.mg-bottom-8px-mbl {
  margin-bottom: 8px;
}

.mg-bottom-8px-mbl {
  margin-bottom: 8px;
}




























@media screen and (max-width: 991px) {
  .w-container {
    max-width: 728px;
  }
}

@media screen and (max-width: 479px) {
  .w-container {
    max-width: none;
  }
}

.wf-layout-layout {
  display: grid;
}

.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

/* .w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
} */

.w-layout-layout {
  grid-row-gap: 50px;
  grid-column-gap: 50px;
  grid-auto-columns: 1fr;
  justify-content: center;
  padding: 20px;
}

.w-layout-cell {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
    max-width: none;
  }
}

h2 {
  color: var(--neutral--800);
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 30px;
  font-weight: 400;
  line-height: 1.4em;
}

h3 {
  color: var(--neutral--800);
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.417em;
}

p {
  margin-bottom: 16px;
}

a {
  color: var(--accent--primary-1);
  text-decoration: underline;
  transition: color .3s;
}

a:hover {
  color: var(--neutral--800);
}

img {
  max-width: 100%;
  display: inline-block;
}

.grid-2-columns {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.grid-2-columns.main-content-grid.v2 {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  grid-template-columns: 1fr 1.9fr;
}

.mg-bottom-64px {
  margin-bottom: 64px;
}

.display-2 {
  color: var(--neutral--800);
  font-size: 56px;
  font-weight: 400;
  line-height: 1.179em;
}

.inner-container._624px {
  max-width: 624px;
}

.mg-bottom-0 {
  margin-bottom: 0;
}

.btn-primary {
  border: 1px solid var(--accent--primary-1);
  background-color: var(--accent--primary-1);
  color: var(--neutral--100);
  text-align: center;
  transform-style: preserve-3d;
  border-radius: 8px;
  justify-content: center;
  padding: 16px 22px;
  font-weight: 700;
  line-height: 1.111em;
  text-decoration: none;
  transition: border-color .3s, background-color .3s, transform .3s, color .3s;
}

.btn-primary:hover {
  border-color: var(--secondary--color-1);
  background-color: var(--secondary--color-1);
  color: var(--neutral--100);
  transform: translate3d(0, -3px, .01px);
}

.btn-primary.button-row {
  margin-right: 28px;
}

.btn-secondary {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #000000;
  color: #000000;
  text-align: center;
  transform-style: preserve-3d;
  border-radius: 5px;
  padding: 15px 15px;
  line-height: 1.111em;
  min-width: 200px;
  transition: transform .3s, box-shadow .3s;
}

.btn-secondary:hover {
  border-color: #007CC2;
  background-color: #007CC2;
  border: 1px solid #007CC2;
  color: #FFFFFF;
  transform: translate3d(0, -6px, .01px);
  box-shadow: 0 15px 32px rgba(23, 136, 228, 0.38);
}

.buttons-row {
  align-items: center;
  display: flex;
}

.container-default {
  max-width: 1268px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.cta-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.container-default.z-index-1 {
  z-index: 1;
  position: relative;
}

.section {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
}

.heading-h2-size {
  color: var(--neutral--800);
  font-size: 30px;
  font-weight: 400;
  line-height: 1.4em;
}

.flex-horizontal {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-horizontal.space-between {
  justify-content: space-between;
}

.flex-horizontal.gap-16px---flex-wrap {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-wrap: wrap;
}

.flex-horizontal.align-and-justify-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-horizontal.align-and-justify-start.gap-column-16px {
  grid-column-gap: 16px;
}

._w-h-100 {
  width: 100%;
  height: 100%;
}

.fit-cover {
  object-fit: cover;
}

.overflow-hidden {
  overflow: hidden;
}

.z-index-1 {
  z-index: 1;
  position: relative;
}

.full-width-content-wrapper {
  width: 100%;
  position: relative;
}

.full-width-content-wrapper.about-us-v2-content-full-width {
  width: 144%;
  border-radius: 20px;
  margin-left: -190px;
  overflow: hidden;
}

.position-absolute-item {
  z-index: -1;
  width: 100%;
  background-color: var(--neutral--800);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: hidden;
}

.position-absolute-item.top {
  height: 50%;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.position-absolute-item.top.home-v1---v2 {
  height: 80%;
  /* background-color: var(--neutral--200); */

  /* background-color: #f5f7fc; */
  background: url("../public/assets/img/bg-23.png") top center !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* /* height: 100vh; */
  /* background-color: #eeeeee; */
  overflow: hidden;
  z-index: -1;
}

.feature-background {
  background: url('../public/assets/img/bg-23.png') no-repeat center center;
  background-size: cover;
  height: 80%;
  z-index: -1;
}

.quick-stack {
  padding: 0;
}

/* .quick-stack.about-us-quick-stack {
  grid-column-gap: 56px;
  grid-row-gap: 72px;
  margin-top: 104px;
  margin-bottom: 180px;
} */

.icon-image {
  object-fit: cover;
}

.icon-image._48px {
  max-height: 48px;
  max-width: 48px;
  min-height: 48px;
  min-width: 48px;
}

@media screen and (max-width: 991px) {
  .grid-2-columns.main-content-grid.v2 {
    grid-template-columns: 1fr;
  }

  .display-2 {
    font-size: 46px;
  }

  .inner-container._100-tablet {
    max-width: 100%;
  }

  .btn-primary.button-row {
    margin-right: 24px;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .full-width-content-wrapper.about-us-v2-content-full-width {
    width: 100%;
    margin-left: 0;
  }

  .quick-stack.about-us-quick-stack {
    grid-column-gap: 40px;
    grid-row-gap: 48px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  h2 {
    margin-bottom: 12px;
    font-size: 28px;
  }

  .grid-2-columns {
    grid-template-columns: 1fr;
  }

  .mg-bottom-64px {
    margin-bottom: 56px;
  }

  .display-2 {
    font-size: 40px;
  }

  .btn-primary.button-row {
    margin-right: 16px;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .heading-h2-size {
    font-size: 28px;
  }

  .flex-horizontal.align-and-justify-start.gap-column-16px {
    grid-column-gap: 12px;
  }

  .full-width-content-wrapper.about-us-v2-content-full-width {
    border-radius: 16px;
  }

  .quick-stack.about-us-quick-stack {
    grid-row-gap: 32px;
  }

  .icon-image._48px {
    max-height: 40px;
    max-width: 40px;
    min-height: 40px;
    min-width: 40px;
  }
}

@media screen and (max-width: 479px) {
  h2 {
    font-size: 26px;
  }

  .display-2 {
    font-size: 36px;
  }

  .btn-primary {
    width: 100%;
  }

  .btn-primary.button-row {
    margin-bottom: 16px;
    margin-right: 0;
  }

  .btn-secondary {
    width: 100%;
  }

  .buttons-row {
    flex-direction: column;
  }

  .container-default {
    padding-left: 20px;
    padding-right: 20px;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .heading-h2-size {
    font-size: 26px;
  }

  .width-100-mbp {
    width: 100%;
  }
}

#w-node-b3c981a1-32f7-bfa0-c890-016edcaa4385-b859b814 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-bc236448-741b-a3e1-c0c8-655675212ef1-b859b814 {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  align-self: start;
}

#w-node-bc236448-741b-a3e1-c0c8-655675212ef2-b859b814,
#w-node-_8fcf6fd6-cd2a-c00d-ec90-03ecda8735d3-b859b814,
#w-node-bc236448-741b-a3e1-c0c8-655675212ef3-b859b814,
#w-node-f5a18d4c-2b28-1f32-54e7-9fe201fddfec-b859b814,
#w-node-_42f8508c-473c-b6d7-bbf5-0ba08290eed9-b859b814,
#w-node-_71d3ab17-c74e-106b-9c03-112900f0173a-b859b814,
#w-node-_07d6ba19-e1a2-7435-490f-a2de21e3dbaa-b859b814,
#w-node-_2d49df43-cd0d-5ac3-8562-593d50b08f37-b859b814 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

@media screen and (max-width: 991px) {
  #w-node-b3c981a1-32f7-bfa0-c890-016edcaa4385-b859b814 {
    order: 9999;
  }
}

@media screen and (max-width: 767px) {
  #w-node-bc236448-741b-a3e1-c0c8-655675212ef1-b859b814 {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
  }

  #w-node-bc236448-741b-a3e1-c0c8-655675212ef2-b859b814,
  #w-node-bc236448-741b-a3e1-c0c8-655675212ef3-b859b814,
  #w-node-_42f8508c-473c-b6d7-bbf5-0ba08290eed9-b859b814,
  #w-node-_07d6ba19-e1a2-7435-490f-a2de21e3dbaa-b859b814 {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }
}




























.section.about-hero {
  padding-top: 120px !important;
  padding-bottom: 120px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(to bottom, transparent 50%, #f3f5fa 50%), url("../public/assets/img/hero-bg-5.png") top center;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}


.paragraph.about-hero,
.paragraph.about-mission {
  margin-bottom: 0;
}

.card {
  /* border: 1px solid #000000; */
  background-color: #FFFFFF;
  /* box-shadow: 0 6px 12px 0 rgba(23, 136, 228, 0.38); */
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.38);
  border-radius: 40px;
  overflow: hidden;
}

.card.about-hero-result {
  width: 100%;
  max-width: 288px;
  border-radius: 20px;
  padding: 42px 20px 33px;
}

.split-content.about-hero-content {
  max-width: 580px;
  margin-top: 15px;
  margin-bottom: 49px;
}

.image.about-hero-1 {
  border-radius: 30px;
  margin-bottom: 40px;
  box-shadow: 0 13px 29px rgba(20, 20, 43, .21);
}

.image.about-us-hero-2 {
  width: 100%;
  /* max-width: 76%; */
  border-radius: 30px;
  box-shadow: 0 15px 32px rgba(23, 136, 228, 0.38);
  /* box-shadow: 0 20px 47px rgba(20, 20, 43, .22); */
}

.image.about-us-hero-3 {
  max-width: 100%;
  border-radius: 30px;
  align-self: flex-end;
  margin-top: auto;
  box-shadow: 0 15px 32px rgba(23, 136, 228, 0.38);
  /* box-shadow: 0 33px 47px rgba(20, 20, 43, .14); */
}

.bg.about-hero {
  z-index: -1;
  min-height: 56%;
  background-color: var(--neutral-200);
  bottom: 0;
  left: 0;
  right: 0;
}

.subtitle-wrapper {
  color: var(--primary-1);
  letter-spacing: .02em;
  align-items: center;
  margin-bottom: 16px;
  font-weight: 700;
  line-height: 1.111em;
  display: flex;
}

.subtitle-shape {
  width: 22px;
  max-height: 2px;
  min-height: 2px;
  background-color: #EB1D2C;
  border-radius: 1000px;
  margin-right: 10px;
}

.bg {
  position: absolute;
}

.bg.about-hero {
  z-index: 1;
  min-height: 56%;
  background-color: #f3f5fa !important;
  bottom: 0;
  left: 0;
  right: 0;
}

.bg.about-hero-blur {
  z-index: -2;
  max-width: 100%;
  top: 0%;
  bottom: 0%;
  right: 0%;
}

.about-hero-wrapper {
  justify-content: space-between;
  margin-bottom: 100px;
  display: flex;
}

.about-hero-results-wrapper {
  text-align: center;
}

.about-hero-content-left {
  max-width: 602px;
  margin-right: 40px;
  align-content: end;
}

.about-us-hero-content-right {
  max-width: 47% !important;
  flex-direction: column !important;
  display: flex !important;
}

.about-hero-results-text {
  letter-spacing: 0;
  margin-bottom: 34px;
  font-size: 22px;
  line-height: 1.636em;
}

.about-hero-results-grid {
  grid-template-rows: auto;
  grid-template-columns: minmax(auto, 288px) minmax(auto, 288px) minmax(auto, 288px) minmax(auto, 288px);
  justify-content: space-between;
}

.card-about-hero-result-number {
  color: var(--neutral-800);
  justify-content: center;
  margin-bottom: 13px;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.069em;
  display: flex;
}

.accent-red {
  color: var(--primary-1);
}

.accent-purple {
  color: var(--secondary-2);
}

.accent-yellow {
  color: var(--secondary-1);
}

.accent-blue {
  color: var(--secondary-3);
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 50px;
  }

  .container-default {
    flex-direction: column;
    align-items: stretch;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section.about-hero {
    padding-top: 65px;
    padding-bottom: 96px;
  }

  .title.about-hero {
    max-width: 491px;
  }

  .split-content.about-hero-content {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 20px;
  }

  .image.about-hero-1 {
    max-width: 45%;
    border-radius: 24px;
    margin-bottom: 0;
  }

  .image.about-hero-2 {
    max-width: 40%;
    border-radius: 24px;
    align-self: flex-end;
  }

  .image.about-hero-3 {
    max-width: 50%;
    border-radius: 24px;
    align-self: auto;
    margin-top: 0;
  }

  .image.about-us-hero-3 {
    max-width: 50%;
    border-radius: 24px;
    align-self: auto;
    margin-top: 0;
  }

  .image.about-us-hero-3 {
    max-width: 50%;
    border-radius: 24px;
    align-self: auto;
    margin-top: 0;
  }

  .subtitle-wrapper {
    margin-bottom: 13px;
  }

  .bg.about-hero-blur {
    max-width: 70%;
    top: auto;
    bottom: 34%;
    right: -17%;
  }

  .about-hero-wrapper {
    flex-direction: column;
    margin-bottom: 83px;
  }

  .about-hero-content-left {
    max-width: 100%;
    align-items: center;
    margin-bottom: 60px;
    margin-right: 0;
    display: flex;
  }

  .about-hero-content-right {
    max-width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .about-hero-results-text {
    margin-bottom: 28px;
  }

  .about-hero-results-grid {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-columns: minmax(auto, 288px) minmax(auto, 288px);
    justify-content: center;
  }

  .card-about-hero-result-number {
    margin-bottom: 10px;
    font-size: 40px;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    margin-bottom: 13px;
    font-size: 42px;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section.about-hero {
    padding-top: 52px;
    padding-bottom: 77px;
  }

  .card {
    border-radius: 35px;
  }

  .title.about-hero {
    max-width: 406px;
  }

  .split-content.about-hero-content {
    margin-bottom: 60px;
    margin-right: 0;
  }

  .image.about-hero-1 {
    border-radius: 30px;
  }

  .image.about-hero-2 {
    max-width: 60%;
    border-radius: 30px;
    align-self: auto;
  }

  .image.about-hero-3 {
    border-radius: 30px;
  }

  .image.about-us-hero-3 {
    border-radius: 30px;
  }

  .subtitle-wrapper {
    margin-bottom: 10px;
  }

  .bg.about-hero {
    min-height: 60%;
  }

  .bg.about-hero-blur {
    bottom: 52%;
    right: -13%;
  }

  .about-hero-wrapper {
    margin-bottom: 69px;
  }

  .about-hero-content-left {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 50px;
  }

  .about-hero-results-text {
    margin-bottom: 23px;
  }

  .about-hero-results-grid {
    grid-column-gap: 20px;
  }

  .card-about-hero-result-number {
    font-size: 33px;
  }

  .card-about-hero-result-text {
    font-size: 16px;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    margin-bottom: 10px;
    font-size: 35px;
  }

  .container-default {
    padding-left: 16px;
    padding-right: 16px;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section.about-hero {
    padding-top: 41px;
    padding-bottom: 61px;
  }

  .card {
    border-radius: 30px;
  }

  .card.about-hero-result {
    max-width: 100%;
    border-radius: 20px;
  }

  .title.about-hero {
    max-width: 335px;
  }

  .split-content.about-hero-content {
    margin-bottom: 50px;
  }

  .image.about-hero-1 {
    border-radius: 20px;
  }

  .image.about-hero-2 {
    max-width: 100%;
    border-radius: 20px;
  }

  .image.about-hero-3 {
    border-radius: 20px;
  }

  .subtitle-wrapper {
    justify-content: flex-start;
  }

  .subtitle-shape {
    display: block;
  }

  .about-hero-wrapper {
    margin-bottom: 58px;
  }

  .about-hero-content-left {
    margin-bottom: 40px;
  }

  .about-hero-results-text {
    margin-bottom: 20px;
  }

  .about-hero-results-grid {
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }

  .card-about-hero-result-number {
    margin-bottom: 6px;
  }
}





/*
About Mission
*/

.section.about-mission {
  overflow: hidden;
}

.paragraph.about-mission {
  margin-bottom: 0;
}

.title.about-mission {
  margin-top: 24px;
  margin-bottom: 24px;
}

.split-content.about-mission-content {
  z-index: 1;
  max-width: 45%;
  margin-right: 20px;
  position: relative;
}

.image.idaas-image-1 {
  max-width: 60%;
  border-radius: 15px;
  align-self: center;
}

.image.about-mission-1 {
  max-width: 75%;
  border-radius: 30px;
  align-self: flex-start;
}

.image.about-mission-2 {
  max-width: 80%;
  border-radius: 30px;
  align-self: flex-end;
  margin-top: -220px;
  box-shadow: 0 14px 44px rgba(63, 128, 253, .08), 0 18px 84px rgba(20, 20, 43, .17);
}

.subtitle-wrapper {
  color: var(--primary-1);
  letter-spacing: .02em;
  align-items: center;
  margin-bottom: 16px;
  font-weight: 700;
  line-height: 1.111em;
  display: flex;
}

.subtitle-shape {
  width: 22px;
  max-height: 2px;
  min-height: 2px;
  background-color: #EB1D2C;
  border-radius: 1000px;
  margin-right: 10px;
}

.bg {
  position: absolute;
}

.bg.about-mission {
  z-index: -1;
  max-width: 187%;
}

.about-mission-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.about-mission-images-wrapper {
  width: 100%;
  max-width: 606px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  margin-bottom: 50px;
}

@media screen and (max-width: 991px) {
  .container-default {
    flex-direction: column;
    align-items: stretch;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .title.about-mission {
    max-width: 594px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .split-content.about-mission-content {
    max-width: 849px;
    text-align: center;
    margin-bottom: 80px;
    margin-right: 0;
  }

  .image.about-mission-1,
  .image.about-mission-2 {
    border-radius: 28px;
  }

  .subtitle-wrapper {
    margin-bottom: 13px;
  }

  .subtitle-wrapper.about-mission {
    justify-content: center;
  }

  .subtitle-shape.about-mission {
    display: none;
  }

  .bg.about-mission {
    max-width: 150%;
  }

  .about-mission-wrapper {
    flex-direction: column;
  }

  .about-mission-images-wrapper {
    max-width: 657px;
  }

  .split-content.home-v3-advantage-image {
    max-width: 663px;
  }
}

@media screen and (max-width: 767px) {
  h2 {
    margin-bottom: 13px;
    font-size: 33px;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .title.about-mission {
    margin-top: 16px;
    margin-bottom: 16px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .split-content.about-mission-content {
    max-width: 647px;
    margin-bottom: 60px;
  }

  .image.about-mission-1 {
    max-width: 60%;
  }

  .image.idaas-image-1 {
    max-width: 50%;
  }

  .image.about-mission-2 {
    max-width: 70%;
    margin-top: -200px;
  }

  .subtitle-wrapper {
    margin-bottom: 10px;
  }

  .bg.about-mission {
    max-width: 100%;
  }
}

@media screen and (max-width: 479px) {
  h2 {
    margin-bottom: 10px;
    font-size: 28px;
  }

  .container-default {
    padding-left: 16px;
    padding-right: 16px;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .title.about-mission {
    margin-bottom: 13px;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .split-content.about-mission-content {
    text-align: left;
    margin-bottom: 50px;
  }

  .image.about-mission-1 {
    border-radius: 40px;
  }

  .image.idaas-image-1 {
    border-radius: 15px;
  }

  .image.about-mission-2 {
    border-radius: 40px;
    margin-top: -96px;
  }

  .subtitle-wrapper {
    justify-content: flex-start;
  }

  .subtitle-wrapper.about-mission {
    justify-content: flex-start;
  }

  .subtitle-shape {
    display: block;
  }

  .subtitle-shape.about-mission {
    display: block;
  }
}







/*
Call To Action
*/
.subscribe-btn {
  text-align: center;
  white-space: nowrap;
  margin-right: 20px;
  display: inline-block;
}

.subscribe-container {
  display: flex;
  background-color: rgb(0, 0, 0, 0);
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 var(--shadow-input);
}

.subscribe-input {
  flex: 1;
  padding: 20px;
  min-height: 60px;
  margin-right: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: rgb(0, 0, 0, 0);
}



.footer-logo-container {
  transform-style: preserve-3d;
  margin-bottom: 19px;
  transition-property: transform;
}

.footer-logo-container:hover {
  transform: scale3d(1.04, 1.04, 1.01);
}

.footer-logo {
  width: 250px;
  margin-left: -3px;
}




.cta-logo-container {
  transform-style: preserve-3d;
  margin-bottom: 19px;
  transition-property: transform;
}

.cta-logo-container:hover {
  transform: scale3d(1.04, 1.04, 1.01);
}

.cta-logo {
  width: 150px;
  margin-left: -3px;
}

#privacyAgreement {
  max-height: 14px !important;
}

.input:hover {
  border-color: var(--primary-1);
}

.input:focus {
  border-color: var(--primary-1);
  color: var(--primary-1);
  box-shadow: 0 2px 11px rgba(244, 38, 62, .06), 0 4px 10px rgba(31, 37, 89, .07);
}

.input::placeholder {
  color: var(--neutral-600);
}

.input.footer-newsletter {
  border: none;
  min-width: 80%;
  min-height: 79px;
  padding-left: 20px;
  /* padding-right: 180px; */
  background: rgb(0, 0, 0, 0);
}

.input.footer-newsletter:active {
  outline: none !important;
}

.paragraph.footer-main-paragraph {
  margin-bottom: 0;
}

.title.h3-size {
  color: var(--neutral-800);
  letter-spacing: .02em;
  font-size: 26px;
  font-weight: 700;
  line-height: 1.346em;
}

.title.h3-size.footer-newsletter {
  margin-bottom: 19px;
}

.split-content.footer-content-top-left {
  max-width: 526px;
  margin-right: 20px;
}

.split-content.footer-content-top-right {
  width: 100%;
  max-width: 530px;
}

.content-top {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.footer-newsletter-form-block {
  margin-bottom: 0;
}

.footer-newsletter-form {
  border-radius: 10px;
  align-items: center;
  display: flex;
  position: relative;
  border: 1px solid #000000;
  box-shadow: 0 15px 32px rgba(23, 136, 228, 0.38);
}

.footer-newsletter-link {
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
}

.link-arrow {
  margin-left: 8px;
  font-family: "Comfortaa", "Open Sans";
  font-size: 13px;
  font-weight: 400;
  line-height: 1em;
}

.success-message {
  background-color: var(--secondary-3);
  box-shadow: 0 2px 6px 0 var(--shadow-input);
  color: var(--neutral-100);
  text-align: center;
  border-radius: 20px;
  align-self: stretch;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 30px 20px;
}

.error-message {
  color: var(--primary-1);
  text-align: center;
  background-color: #ffeef0;
  border-radius: 20px;
  align-self: stretch;
  margin-top: 20px;
  padding: 20px;
}

@media screen and (max-width: 991px) {
  .button-primary {
    padding-left: 40px;
    padding-right: 40px;
  }

  .footer-logo-container {
    margin-bottom: 16px;
  }

  .footer-logo {
    width: 198px;
  }

  .cta-logo-container {
    margin-bottom: 16px;
  }

  .cta-logo {
    width: 198px;
  }

  .split-content.footer-content-top-left {
    margin-bottom: 40px;
    margin-right: 0;
  }

  .content-top.footer-content-top {
    flex-direction: column;
    align-items: stretch;
  }
}

@media screen and (max-width: 767px) {
  h2 {
    margin-bottom: 13px;
    font-size: 33px;
  }

  .footer-logo {
    width: 180px;
  }

  .cta-logo {
    width: 15px;
  }

  .title.h3-size {
    font-size: 22px;
  }

  .title.h3-size.footer-newsletter {
    margin-bottom: 16px;
  }

  .split-content.footer-content-top-left {
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 479px) {
  h2 {
    margin-bottom: 10px;
    font-size: 28px;
  }

  .button-primary {
    padding: 15px 40px;
    font-size: 16px;
    display: block;
  }

  .button-primary.footer-newsletter {
    margin-top: 20px;
    padding: 15px 40px;
    position: static;
  }

  .input {
    padding-left: 16px;
    padding-right: 16px;
  }

  .input.footer-newsletter {
    min-height: 66px;
    padding-left: 23px;
    padding-right: 23px;
  }

  .input.footer-newsletter:active {
    outline: 0 !important;
  }

  .input.footer-newsletter:focus {
    outline: none;
  }

  .title.h3-size {
    font-size: 20px;
  }

  .title.h3-size.footer-newsletter {
    margin-bottom: 13px;
  }

  .split-content.footer-content-top-left {
    margin-bottom: 30px;
  }

  .footer-newsletter-form {
    flex-direction: row;
    align-items: stretch;
  }

  .success-message,
  .error-message {
    font-size: 16px;
  }
}

/*! CSS Used fontfaces */
@font-face {
  font-family: "Comfortaa", "Open Sans";
  src: url('https://assets.website-files.com/605e2cb592c7e2dd9eea172c/606e2288765e0167b711bd09_icons-marketing-template.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}












.split-content.home-v3-advantage-image {
  max-width: 606px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}















.image.list-icon {
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  border-radius: 1000px;
  margin-right: 12px;
}

.image.list-icon.red-shadow {
  box-shadow: 0 3px 16px rgba(255, 57, 81, .1);
}

.image.home-v3-advantage {
  border-radius: 50px;
  box-shadow: 0 16px 104px rgba(20, 20, 43, .06);
}

.subtitle-wrapper {
  color: var(--primary-1);
  letter-spacing: .02em;
  align-items: center;
  margin-bottom: 16px;
  font-weight: 700;
  line-height: 1.111em;
  display: flex;
}

.subtitle-shape {
  width: 22px;
  max-height: 2px;
  min-height: 2px;
  background-color: var(--primary-1);
  border-radius: 1000px;
  margin-right: 10px;
}

.list-wrapper {
  align-items: center;
  display: flex;
}

.list-text {
  color: var(--neutral-800);
  letter-spacing: .02em;
  font-weight: 500;
  line-height: 1.111em;
}

.bg {
  position: absolute;
}

.bg.home-v3-advantage {
  z-index: -1;
  max-width: 187%;
  bottom: -207px;
  left: -301px;
}

.home-v3-advantage-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.home-v3-advantage-features-grid {
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  margin-bottom: 40px;
}

.button-primary-arrow {
  font-family: "Comfortaa", "Open Sans";
  font-size: 13px;
  font-weight: 400;
  line-height: 1em;
  display: inline-block;
  position: relative;
  top: -2px;
}

@media screen and (max-width: 991px) {
  .container-default {
    flex-direction: column;
    align-items: stretch;
  }

  .button-primary {
    padding-left: 40px;
    padding-right: 40px;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .title.home-v3-advantage {
    max-width: 524px;
    margin-left: auto;
    margin-right: auto;
  }

  .split-content.home-v3-advantage-content {
    max-width: 616px;
    text-align: center;
    margin-bottom: 80px;
    margin-right: 0;
  }

  .split-content.home-v3-advantage-image {
    max-width: 663px;
  }

  .subtitle-wrapper {
    margin-bottom: 13px;
  }

  .subtitle-wrapper.home-v3-advantage {
    justify-content: center;
  }

  .subtitle-shape.home-v3-advantage {
    display: none;
  }

  .bg.home-v3-advantage {
    max-width: 165%;
    bottom: -101px;
    left: auto;
  }

  .home-v3-advantage-wrapper {
    flex-direction: column;
  }

  .home-v3-advantage-features-grid {
    justify-items: center;
  }
}

@media screen and (max-width: 767px) {
  h2 {
    margin-bottom: 13px;
    font-size: 33px;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .title.home-v3-advantage {
    margin-left: 0;
  }

  .split-content.home-v3-advantage-content {
    text-align: left;
    margin-bottom: 60px;
  }

  .split-content.home-v3-advantage-image {
    max-width: 100%;
  }

  .image.home-v3-advantage {
    border-radius: 40px;
  }

  .subtitle-wrapper {
    margin-bottom: 10px;
  }

  .subtitle-wrapper.home-v3-advantage {
    justify-content: flex-start;
  }

  .subtitle-shape.home-v3-advantage {
    display: block;
  }

  .home-v3-advantage-wrapper {
    align-items: stretch;
  }

  .home-v3-advantage-features-grid {
    justify-items: stretch;
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 479px) {
  h2 {
    margin-bottom: 10px;
    font-size: 28px;
  }

  .container-default {
    padding-left: 16px;
    padding-right: 16px;
  }

  .button-primary {
    padding: 15px 40px;
    font-size: 16px;
    display: block;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .split-content.home-v3-advantage-content {
    margin-bottom: 50px;
  }

  .image.list-icon {
    margin-right: 10px;
  }

  .image.home-v3-advantage {
    border-radius: 30px;
  }

  .subtitle-wrapper {
    justify-content: flex-start;
  }

  .subtitle-shape {
    display: block;
  }

  .list-wrapper {
    font-size: 16px;
  }
}

/*! CSS Used fontfaces */
@font-face {
  font-family: "Comfortaa", "Open Sans";
  src: url('https://assets.website-files.com/605e2cb592c7e2dd9eea172c/606e2288765e0167b711bd09_icons-marketing-template.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}









































/* 
.w-container {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}

.w-container:before,
.w-container:after {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-container:after {
  clear: both;
} */

/* @media screen and (max-width: 991px) {
  .w-container {
      max-width: 728px;
  }
}

@media screen and (max-width: 479px) {
  .w-container {
      max-width: none;
  }
} */

/* .w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
} */

/* .w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
} */

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
      max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
      max-width: none;
  }
}

h2 {
  color: var(--core--colors--neutral--800);
  margin-top: 0;
  margin-bottom: 0;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.112em;
}

a {
  color: var(--core--colors--neutral--800);
  text-decoration: underline;
  transition: color .3s;
}

a:hover {
  color: var(--core--colors--primary--100);
}

img {
  max-width: 100%;
  display: inline-block;
}

.display-12 {
  font-size: 80px;
  line-height: 1.125em;
}

.display-10 {
  font-size: 56px;
  line-height: 1.071em;
}

.mg-top-8px {
  margin-top: 8px;
}

.grid-2-columns {
  grid-column-gap: 22px;
  grid-row-gap: 22px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.grid-2-columns.stats-and-title {
  grid-column-gap: 40px;
  grid-template-columns: 1fr 1.1fr;
  align-items: center;
}

.text-neutral-100 {
  color: var(--core--colors--neutral--100);
}

.flex-horizontal {
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-horizontal.space-between {
  justify-content: space-between;
}

.flex-horizontal.gap-18px---flex-wrap {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  flex-wrap: wrap;
}

.grid-1-column {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.grid-1-column.video-top---stats-bottom {
  grid-row-gap: 100px;
}

.inner-container {
  max-width: 1200px;
}

.inner-container._700px {
  max-width: 700px;
}

.paragraph-large {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.667em;
}

.text-color-primary-1 {
  color: #EB1D2C;
}

.image-wrapper {
  overflow: hidden;
}

/* .container-default {
  max-width: var(--core--spacing--container-default--default);
  padding-right: var(--core--spacing--container-default--padding--default);
  padding-left: var(--core--spacing--container-default--padding--default);
  margin-left: auto;
  margin-right: auto;
} */

.section {
  padding-top: 100px;
  padding-bottom: 100px;
}

.position-relative {
  position: relative;
}

.shape-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.shape-wrapper.shape-bottom---right {
  z-index: -1;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.shape-wrapper.shape-width-72 {
  max-width: 72%;
}

.vertical-line-decoration {
  background-color: #EB1D2C;
  width: 3px;
  min-width: 3px;
  height: 40px;
}

.vertical-line-decoration.section-line {
  margin-top: 6px;
}

.line-left---content {
  grid-column-gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.border-radius-36px {
  border-radius: 24px;
}

.bg-image-overlay {
  z-index: -1;
  background-color: rgba(9, 14, 11, .5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.bg-image-overlay.video-overlay {
  z-index: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.bold {
  font-family: "Comfortaa", "Open Sans";
  font-weight: 700;
}

.half-bg-bottom {
  z-index: -1;
  /* background-color: var(--core--colors--primary--200); */
  /* background: linear-gradient(90deg, rgba(0, 123, 194, 1) 0%, rgba(235, 29, 44, 1) 100%); */
  background: url("../public/assets/img/bg-23.png") top center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* /* height: 100vh; */
  /* background-color: #eeeeee; */
  overflow: hidden;

  min-height: 40%;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.half-bg-bottom.dark-bg {
  background: url("../public/assets/img/bg-23.png") top center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* /* height: 100vh; */
  /* background-color: #eeeeee; */
  overflow: hidden;


  /* background-color: var(--core--colors--neutral--800); */
  /* background: linear-gradient(90deg, rgba(0, 123, 194, 1) 0%, rgba(235, 29, 44, 1) 100%); */
  min-height: 50%;
}

.play-button {
  transform-style: preserve-3d;
  transition: transform .3s;
}

.play-button:hover {
  transform: scale3d(.96, .96, 1.001);
}

.video-bg-image {
  object-fit: cover;
  width: 100%;
}

@media screen and (max-width: 991px) {
  h2 {
      font-size: 32px;
  }

  .display-12 {
      font-size: 68px;
  }

  .display-10 {
      font-size: 48px;
  }

  .grid-2-columns.stats-and-title {
      grid-row-gap: 40px;
      grid-template-columns: 1fr;
  }

  .grid-1-column.video-top---stats-bottom {
      grid-row-gap: 100px;
  }

  .section {
      padding-top: 100px;
      padding-bottom: 100px;
  }

  .half-bg-bottom.dark-bg {
      min-height: 60%;
  }
}

@media screen and (max-width: 767px) {
  h2 {
      font-size: 26px;
  }

  .display-12 {
      font-size: 58px;
  }

  .display-10 {
      font-size: 42px;
  }

  .grid-2-columns {
      grid-template-columns: 1fr;
  }

  .grid-1-column.video-top---stats-bottom {
      grid-row-gap: 62px;
  }

  .paragraph-large {
      font-size: 16px;
  }

  .container-default {
      padding-right: var(--core--spacing--container-default--padding--mb);
      padding-left: var(--core--spacing--container-default--padding--mb);
  }

  .section {
      padding-top: 80px;
      padding-bottom: 80px;
  }

  .vertical-line-decoration.section-line {
      margin-top: 2px;
  }

  .line-left---content {
      grid-column-gap: 16px;
  }

  .border-radius-36px {
      border-radius: 24px;
  }

  .play-button {
      max-width: 80px;
  }
}

@media screen and (max-width: 479px) {
  h2 {
      font-size: 24px;
  }

  .display-12 {
      font-size: 42px;
  }

  .display-10 {
      font-size: 34px;
  }

  .container-default {
      padding-left: 18px;
      padding-right: 18px;
  }

  .section {
      padding-top: 72px;
      padding-bottom: 72px;
  }

  .shape-wrapper.shape-width-72 {
      max-width: 96%;
  }

  .vertical-line-decoration.section-line {
      height: 32px;
  }

  .border-radius-36px {
      border-radius: 18px;
  }

  .half-bg-bottom.dark-bg {
      min-height: 70%;
  }

  .play-button {
      max-width: 64px;
  }
}
























.mg-top-32px {
  margin-top: 32px;
}

.businesses-card---v1 {
  display: block;
  text-align: center;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.businesses-card---v1:hover {
  transform: scale(1.05);
}

.business-card-content-wrapper {
  padding: 16px;
}

.business-image {
  width: 100%;
  height: 320px;
  object-fit: cover;
}

.business-card-text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.businesses-card---v1:hover .business-card-text-overlay {
  opacity: 1;
}













.inner-container.center {
  margin-left: auto;
  margin-right: auto;
}

.inner-container._575px {
  max-width: 575px;
}

/* Typography classes */
.medium {
  font-weight: 500;
}

.display-11 {
  font-size: 72px;
  line-height: 1em;
}

.display-8 {
  font-size: 36px;
  line-height: 1.111em;
}

.mg-top-16px {
  margin-top: 16px;
}

/* Image settings */
.image-wrapper {
  overflow: hidden;
}

.image-wrapper.service-image-top---v1 {
  object-position: 50% 100%;
  width: 100%;
  max-width: 426px;
}

.image-wrapper.service-image-left---v1 {
  align-self: center;
  width: 148%;
  max-width: 549px;
  margin-top: 43px;
}

.image-wrapper.width-100 {
  width: 100%;
}

.image {
  width: 100%;
  max-width: 100%;
  display: inline-block;
}

.image-wrapper.service-image-bottom {
  max-width: 320px;
}

/* Card styles */
.card {
  background-color: var(--neutral--100);
  color: var(--neutral--600);
  border-radius: 20px;
  overflow: hidden;
}

.card.service-card---v1 {
  background-color: var(--neutral--700);
  min-height: 100%;
  padding: 48px 40px;
  position: relative;
}

.card.service-card---v1.white-top---main {
  grid-column-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  background-color: var(--neutral--300);
  padding: 48px 56px 0;
  display: grid;
}

.card.service-card---v1.white-left---main {
  background-color: var(--neutral--300);
  flex-direction: column;
  padding-bottom: 34px;
  display: flex;
}

.card.service-card---v1.white-right---main {
  background-color: var(--neutral--300);
  flex-direction: column;
  padding: 61px 56px 40px;
  display: flex;
}

.service-card-bottom-content---v1 {
  max-width: 368px;
  margin-top: 63px;
}

.service-card---v1---top---content {
  padding-top: 60px;
  padding-bottom: 60px;
}

/* Buttons */
.primary-button-icon {
  border: 1px solid var(--neutral--800);
  background-color: var(--neutral--800);
  color: var(--neutral--100);
  text-align: center;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1em;
  display: flex;
  transition: box-shadow 0.3s, color 0.3s, border-color 0.3s, background-color 0.3s, transform 0.3s;
}

.primary-button-icon:hover {
  color: var(--neutral--100);
  transform: scale(0.94);
}

.primary-button-icon.large {
  width: 48px;
  height: 48px;
  font-size: 22px;
}

.primary-button-icon.white {
  border-color: var(--illustration--100);
  background-color: var(--neutral--100);
  color: var(--neutral--800);
}

/* Button positioning */
.top-right-absolute-button {
  position: absolute;
  inset: 30px 30px auto auto;
}

.bottom-right-button-absolute {
  position: absolute;
  bottom: 40px;
  right: 30px;
}

/* Responsive adjustments */
@media screen and (max-width: 991px) {
  .card.service-card---v1.white-top---main {
    padding-left: 32px;
    padding-right: 32px;
  }

  .card.service-card---v1.white-left---main,
  .card.service-card---v1.white-right---main {
    padding-top: 40px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .image-wrapper.service-image-top---v1 {
    margin-top: 40px;
  }

  .image-wrapper.service-image-left---v1 {
    width: 140%;
  }

  .service-card-bottom-content---v1 {
    margin-right: 72px;
  }
}

@media screen and (max-width: 767px) {
  .card.service-card---v1.white-top---main {
    text-align: center;
    grid-template-columns: 1fr;
    justify-items: center;
    padding-top: 40px;
  }

  .primary-button-icon.large.white {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }

  .image-wrapper.service-image-top---v1 {
    max-width: 350px;
    max-height: 310px;
    margin-top: 0;
  }

  .image-wrapper.service-image-left---v1 {
    max-width: 350px;
    margin-top: 40px;
  }

  .service-card-bottom-content---v1 {
    margin-right: 45px;
  }

  .service-card---v1---top---content {
    max-width: 450px;
    padding-top: 50px;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 479px) {
  .card.service-card---v1.white-top---main {
    grid-row-gap: 40px;
    padding-top: 32px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .card.service-card---v1.white-left---main,
  .card.service-card---v1.white-right---main {
    padding: 32px 24px;
  }

  .image-wrapper.service-image-top---v1 {
    max-width: 300px;
    max-height: 270px;
  }

  .image-wrapper.service-image-left---v1 {
    width: 127%;
    min-width: 268px;
    max-width: none;
    margin-top: 32px;
  }

  .image-wrapper.service-image-bottom {
    max-width: 300px;
    max-height: 270px;
  }

  .top-right-absolute-button {
    top: 20px;
    right: 20px;
  }

  .bottom-right-button-absolute {
    bottom: 26px;
    right: 20px;
  }

  .service-card-bottom-content---v1 {
    margin-top: 43px;
    margin-right: 50px;
  }

  .service-card---v1---top---content {
    padding-top: 40px;
  }
}



/* Loading.css */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
  flex-direction: column;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s infinite linear;
  margin-bottom: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

